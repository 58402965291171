import React, { useState, useContext,useEffect } from 'react';
import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
import { SycadContext } from '../../contexts/SycadContext';
import { envoyerRequeteApi } from '../apis/api';

const AddCommande = ({ show, onHide, refreshDemandes }) => {
  const { user } = useContext(SycadContext);
  const [quantity, setQuantity] = useState('');
  const [tickets, setTickets] = useState(0);
  const currentDate = new Date().toISOString().split('T')[0]; // Format YYYY-MM-DD
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (show) {
      setQuantity('');
      setTickets(0);
      setMessage('');
    }
  }, [show]);

  const handleQuantityChange = (e) => {
    const qte = e.target.value;
    setQuantity(qte);
    setTickets(calculateTickets(Number(qte)));
  };

  const handleCreate = async () => {
    if (!user || !user.id_agent) {
      console.error("User or user.id_agent is undefined");
      setMessage("User or user.id_agent is undefined");
      return;
    }

    let query = `
      SELECT * FROM create_demande(${quantity}, ${user.id_agent})
    `;

    try {
      const result = await envoyerRequeteApi(query, '');

      console.log(result);

      if (result.datas) {
        const idDemande = result.datas[0]?.create_demande?.id_demande;
        if (idDemande && idDemande !== "0") {
          setMessage("Demande créée avec succès.");
          refreshDemandes(); // Refresh the list of demandes
          onHide(); // Close the modal
        } else {
          setMessage("Erreur lors de la création de la demande : Ce compte n'est pas autorisé ");
        }
      } else {
        setMessage(`Erreur lors de la création de la demande : ${result.message}`);
      }
    } catch (error) {
      console.error('Error creating demande', error);
      setMessage(`Erreur lors de la création de la demande : ${error.message}`);
    }
  };

  const calculateTickets = (qteCmde) => {
    return Math.ceil(qteCmde / 10);
  };

  const isQuantityValid = Number(quantity) >= 100;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Nouvelle Commande</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FloatingLabel controlId="dateCommande" label="Date de commande" className="mb-3">
            <Form.Control type="date" value={currentDate} readOnly />
          </FloatingLabel>
          <FloatingLabel controlId="quantity" label="Quantité (litres)" className="mb-3">
            <Form.Control
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              placeholder="Entrez la quantité"
            />
          </FloatingLabel>
          <FloatingLabel controlId="tickets" label="Nombre de tickets" className="mb-3">
            <Form.Control type="text" value={tickets} readOnly />
          </FloatingLabel>
          {!isQuantityValid && quantity !== '' && (
            <Form.Text className="text-danger">
              La quantité doit être d'au moins 100 litres.
            </Form.Text>
          )}
        </Form>
        {message && <p>{message}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Annuler</Button>
        <Button 
          variant="primary" 
          onClick={handleCreate} 
          disabled={!isQuantityValid || !user || !user.id_agent}
        >
          Créer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCommande;
