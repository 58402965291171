import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { envoyerRequeteApi } from './apis/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const GrVentesParPetrolierPeriodeRegion = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let query = `SELECT * FROM v_vente`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setData(response.datas);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const totalParPetrolier = data.reduce((acc, vente) => {
    acc[vente.nom_petrolier] = (acc[vente.nom_petrolier] || 0) + vente.qte;
    return acc;
  }, {});

  const totalParPeriode = data.reduce((acc, vente) => {
    const periode = new Date(vente.date_mouv).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit' });
    acc[periode] = (acc[periode] || 0) + vente.qte;
    return acc;
  }, {});

  const totalParRegion = data.reduce((acc, vente) => {
    acc[vente.code_region] = (acc[vente.code_region] || 0) + vente.qte;
    return acc;
  }, {});

  const chartDataParPetrolier = {
    labels: Object.keys(totalParPetrolier),
    datasets: [{
      label: 'Total par pétrolier',
      data: Object.values(totalParPetrolier),
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
    }],
  };

  const chartDataParPeriode = {
    labels: Object.keys(totalParPeriode),
    datasets: [{
      label: 'Total par période',
      data: Object.values(totalParPeriode),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      fill: false,
      borderColor: 'rgba(75, 192, 192, 1)',
      tension: 0.1
    }],
  };

  const chartDataParRegion = {
    labels: Object.keys(totalParRegion),
    datasets: [{
      label: 'Total par région',
      data: Object.values(totalParRegion),
      backgroundColor: 'rgba(255, 206, 86, 0.6)',
    }],
  };

  return (
    <div>
      <h2>Ventes par Pétrolier, Période et Région</h2>
      <div className="chart-container">
        <Bar data={chartDataParPetrolier} />
        <Line data={chartDataParPeriode} />
        <Bar data={chartDataParRegion} />
      </div>
    </div>
  );
};

export default GrVentesParPetrolierPeriodeRegion;
