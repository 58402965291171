import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { Container, Form, Button, Row, Col, FloatingLabel, Pagination, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import PopupRemisePoste from './PopupRemisePoste';
import '../styles/ListSubventions.css';

const ListSubventionPoste = () => {
  const { user, connectedPoste } = useContext(SycadContext);
  const [subventions, setSubventions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [postes, setPostes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showPopupRemisePoste, setShowPopupRemisePoste] = useState(false);
  const [selectedSubvention, setSelectedSubvention] = useState(null);
  const [ticketsDispo, setTicketsDispo] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const subventionsPerPage = 20;

  const isPosteUser = user.code_groupe === 'POSTE';

  useEffect(() => {
    if (isPosteUser && connectedPoste) {
      setSelectedPoste(user.id_sgroupe || '');
      console.log('Poste connecté :', connectedPoste);
    }
  }, [user, connectedPoste, isPosteUser]);

  const fetchSubventions = useCallback(async () => {
    let query = `SELECT * FROM list_subventionposte`;
    if (isPosteUser) {
      query += ` WHERE id_poste = '${user.id_sgroupe}'`;
    } else {
      query += ` WHERE 1=1`;
    }
    query += ` ORDER BY id_remise DESC`;
    
    try {
      const response = await envoyerRequeteApi(query, '');
      console.log("Réponse des subventions:", response.datas);
      setSubventions(response.datas);
    } catch (error) {
      console.error('Error fetching subventions', error);
      setSubventions([]);
    }
  }, [isPosteUser, user.id_sgroupe]);

const fetchRegions = useCallback(async () => {
    let query = `
      SELECT btrim(nom_region) as a_region, btrim(iso_code_region) as code_region
      FROM regions
      ORDER BY nom_region`;

    console.log("Requête pour récupérer les régions:", query);

    try {
      const response = await envoyerRequeteApi(query, '');
      console.log("Réponse des régions:", response.datas);
      setRegions(response.datas);
    } catch (error) {
      console.error('Error fetching regions', error);
    }
  }, []);

  const fetchPostes = useCallback(async () => {
    let query = `
      SELECT DISTINCT id_poste, btrim(nom_poste) as nom_poste
      FROM list_subventionposte`;

    if (user.code_groupe === 'POSTE') {
      query += ` WHERE id_poste = '${user.id_sgroupe}'`;
    }
    query += ` ORDER BY nom_poste`;
    console.log("Requête pour récupérer les postes:", query);

    try {
      const response = await envoyerRequeteApi(query, '');
      console.log("Réponse des postes:", response.datas);
      setPostes(response.datas);
    } catch (error) {
      console.error('Error fetching postes', error);
    }
  }, [user]);

  const fetchTicketsDispo = useCallback(async () => {
    try {
      let query
      if (user.code_groupe === 'POSTE') {
        query = `select coaleace(sum(qte),0) as tickets_dispo 
        from ticket_carb_poste 
        where  id_poste = ${user.id_sgroupe}`;
      }
      else {
        query = `select sum(qte) as tickets_dispo from ticket_carb tc`;
      }
      console.log("Requête pour récupérer les tickets disponibles:", query);
      const response = await envoyerRequeteApi(query, '');
      console.log("Réponse des tickets disponibles:", response.datas);
      setTicketsDispo(response.datas[0].tickets_dispo);
    } catch (error) {
      console.error('Error fetching tickets dispo', error);
    }
  }, [user.code_groupe, user.id_sgroupe]);

  useEffect(() => {
    fetchSubventions();
    fetchRegions();
    fetchPostes();
    fetchTicketsDispo();
  }, [fetchSubventions, fetchRegions, fetchPostes, fetchTicketsDispo,refreshTrigger]);

  const handleRefresh = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  const handlePopupClose = useCallback(() => {
    setShowPopupRemisePoste(false);
    handleRefresh();
  }, [handleRefresh]);

  const filteredSubventions = useMemo(() => {
    return subventions.filter(subvention => 
      (!selectedRegion || subvention.code_region === selectedRegion) &&
      (!selectedPoste || subvention.id_poste === selectedPoste) &&
      (!matriculeFilter || (subvention.matricule && subvention.matricule.toLowerCase().includes(matriculeFilter.toLowerCase())))
    );
  }, [subventions, selectedRegion, selectedPoste, matriculeFilter]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleVoirClick = (subvention) => {
    setSelectedSubvention(subvention);
    setShowPopupRemisePoste(true);
  };

  const indexOfLastSubvention = currentPage * subventionsPerPage;
  const indexOfFirstSubvention = indexOfLastSubvention - subventionsPerPage;
  const currentSubventions = filteredSubventions.slice(indexOfFirstSubvention, indexOfLastSubvention);

  const totalTicketsRemis = filteredSubventions.reduce((acc, subvention) => {
    // Vérifie si l'état de la subvention est "OK"
    if (subvention.etat_actuel === 'OK') {
      // Si oui, ajoute le ticket_dispo au total, sinon ajoute 0
      return acc + (subvention.ticket_dispo || 0);
    } else {
      // Sinon, ne rien ajouter au total
      return acc;
    }
  }, 0);
  const totalPages = Math.ceil(filteredSubventions.length / subventionsPerPage);
  const isUserAuthorized = user && user.code_groupe === "POSTE";

  return (
    <>
      <Header user={user} />
      <Container className="mt-4">
        <h2>Liste des Demandes de carburant</h2>
        <Form className="mb-4">
         <Row>
            <Col>
              <FloatingLabel controlId="regionSelect" label="Région">
                <Form.Select
                  aria-label="Région"
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                  disabled={user.code_groupe === 'POSTE'}
                >
                  {user.code_groupe === 'POSTE' && connectedPoste ? (
                    <option value={connectedPoste.code_region}>{connectedPoste.code_region}</option>
                  ) : (
                    <>
                      <option value="">Toutes les régions</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region.code_region}>
                          {region.a_region}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="posteSelect" label="Poste">
                <Form.Select
                  aria-label="Poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e.target.value)}
                  disabled={user.code_groupe === 'POSTE'}
                >
                  {user.code_groupe === 'POSTE' && connectedPoste ? (
                    <option value={connectedPoste.id_poste}>{connectedPoste.nom_poste}</option>
                  ) : (
                    <>
                      <option value="">Tous les postes</option>
                      {postes.map((poste, index) => (
                        <option key={index} value={poste.id_poste}>
                          {poste.nom_poste}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </FloatingLabel>
            </Col>            
            <Col>
              <FloatingLabel controlId="matriculeFilter" label="Matricule">
                <Form.Control
                  type="text"
                  value={matriculeFilter}
                  onChange={(e) => setMatriculeFilter(e.target.value)}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </Form>
       <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>
            {user.code_groupe === 'PECHE'
              ? `${totalTicketsRemis} tickets remis sur ${ticketsDispo} disponibles soit (${ticketsDispo - totalTicketsRemis}) restants`
              : `Total des tickets reçus : ${totalTicketsRemis}`}
          </strong>
        </div>

      
        <button
          className="bouton-nouveau"
          onClick={() => setShowPopupRemisePoste(true)}
          disabled={!isUserAuthorized}
          hidden={!isUserAuthorized}
        >
          Nouvelle demande
        </button>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Département</th>
              <th>Poste</th>
              <th>Nombre(Qté)</th>
              <th>Référence</th>
              <th>Etat</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {currentSubventions.map((subvention) => (
            <tr key={subvention.id_remise}>
              <td>{new Date(subvention.date_demande).toLocaleDateString()}</td>
              <td>{subvention.nom_departement}</td>
              <td>{subvention.nom_poste}</td>
              <td>{subvention.ticket_dispo}</td>
              <td>{subvention.ref_demande}</td>
              <td>{subvention.etat_actuel}</td>
              <td>
                <Button variant="info" onClick={() => handleVoirClick(subvention)}>Voir</Button>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </Container>
      <PopupRemisePoste
        show={showPopupRemisePoste}
        onHide={handlePopupClose}
        connectedPoste={connectedPoste}
        user={user}
        subvention={selectedSubvention}
        onRefresh={handleRefresh}
      />
    </>
  );
};

export default ListSubventionPoste;