import React, { useState, useContext } from 'react';
import { envoyerRequeteApi } from './apis/api';
import { useNavigate } from 'react-router-dom';
import './styles/Login.css';
import { SycadContext } from '../contexts/SycadContext';
import { AuthContext } from '../contexts/AuthContext';
import Header from './Header';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { setUser, setAppParams, setConnectedStation, setConnectedPoste, setConnectedPetrolier, setIsAdmin } = useContext(SycadContext);
  const { login } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    let query = `
      SELECT * from connexion_agent('${username}', '${password}')
    `;
    console.log("Query: connexion_agent : ", query);
    try {
      const response = await envoyerRequeteApi(query, '');
      console.log("Response: connexion_agent : ", response.datas[0].result);
      const data = response.datas[0].connexion_agent;
     
    //  const parsedData = JSON.parse(data);
      
      if (data.donnee.code === "404") {
        setErrorMessage(data.donnee);
        return;
      }

      const user = data.donnee.user_connecte;
      const sousGroupe = data.donnee.sous_groupe;

      const appParamsLoaded = await loadAppParams(1);
      if (!appParamsLoaded) {
        setErrorMessage("L'accès est bloqué pour cette agence.");
        return;
      }

      if (user ){
        setUser(user);
        login(user); // 
          if (user.code_groupe === 'ADMIN' || user.code_groupe === 'SADMIN' || user.code_groupe === 'COMMISSION' || user.code_groupe === 'DOUANE' || 
            user.code_groupe === 'PECHE' ) {
           
              redirectUser(user.code_groupe);
          } else {
            if (sousGroupe){
              const success = await afficherUser(user, sousGroupe);
              if (success) {
               
                redirectUser(user.code_groupe);
              } else {
                setErrorMessage("Votre demande de connexion a échoué. Veuillez vérifier vos identifiants.");
                return;
              }
            }
            else {
              setErrorMessage("Votre demande de connexion a échoué. Veuillez vérifier vos identifiants.");
              return;
            }
          }
       
        }
        else {
          setErrorMessage("Votre demande de connexion a échoué. Veuillez vérifier vos identifiants.");
          return;
        }
     } catch (error) {
      
      setErrorMessage("Erreur lors de la connexion. Veuillez réessayer.");
    }
  };

  const redirectUser = (codeGroupe) => {
    switch (codeGroupe) {
      case "STATION":
        navigate('/station');
        break;
      case "POSTE":
        navigate('/poste');
        break;
      case "SADMIN":
        navigate('/systeme');
        break;
      case "PECHE":
        navigate('/peche');
        break;
      case "DOUANE":
        navigate('/douane');
        break;
      case "PETROLIER":
      case "COMMISSION":
        navigate('/petrolier');
        break;
      default:
        navigate('/admin');
        break;
    }
  };

  const loadAppParams = async (pid_agence) => {
    if (!pid_agence) {
      pid_agence = 1;
    }
    let query = `SELECT * FROM get_params(1)`;

    try {
      const response = await envoyerRequeteApi(query, '');
      const jDatas = response.datas;
      const params = jDatas[0].get_params;

      if (params.nom_app) {
        setAppParams({
          List_Groupes: params.list_groupes,
          List_Regions: params.list_regions,
          List_Etats: params.list_etats,
          List_Cartes: params.list_cartes,
          List_Stations: params.list_stations,
          List_Petroliers: params.list_petroliers,
          List_Postes: params.list_postes,
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error loading app params", error);
      return false;
    }
  };

  const afficherUser = async (user, sousGroupe) => {
    const sGroup = user.code_groupe;
    const nID_GROUP = user.id_groupe;
    const IsAdmin = [1, 2, 3, 4, 15].includes(nID_GROUP);
    setIsAdmin(IsAdmin);
   
    switch (sGroup) {
      case "STATION":
        if (sousGroupe && sousGroupe.actif) {  
          setConnectedStation(sousGroupe);
          return true;
        }
          break;
      case "POSTE":
        if (sousGroupe && sousGroupe.actif) {
          setConnectedPoste(sousGroupe);
          return true;
        }
        break;
    
      case "PETROLIER":
        if (sousGroupe && sousGroupe.actif) {  
      setConnectedPetrolier(sousGroupe);  
      return true;
        }
        break;

      default:
        return false;
    }
    //return sousGroupe.actif && !sousGroupe.bloquer;
    return false;
  };

  return (
    <>
      <Header />
      <div className="login-background">
        <div className="wave-animation"></div>
        <div className="login-container">
          <div className="login-card">
            <img className="login-card-image" src={`${process.env.PUBLIC_URL}/images/user-icon.png`} alt="User Icon" />
            <div className="login-card-body">
              <h2 className="text-center">Connexion</h2>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              <form onSubmit={handleLogin}>
                <div className="loginform-group">
                  <label className="loginlabel">Nom d'utilisateur</label>
                  <input
                    type="text"
                    className="logininput"
                    id="formBasicEmail"
                    placeholder="Entrez votre nom d'utilisateur"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label className="loginlabel">Mot de passe</label>
                  <input
                    type="password"
                    className="logininput"
                    id="formBasicPassword"
                    placeholder="Mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button type="submit" className="loginrounded-button">
                  Se connecter
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
