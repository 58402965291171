import React, { useContext } from 'react';
import { SycadContext } from '../contexts/SycadContext';
import DynamicNavbar from './DynamicNavbar';
import { Link } from 'react-router-dom';

const Header = () => {
  const { user } = useContext(SycadContext);

  return (
    <header>
      <div className="header-container">
        <Link to="/">
          <img src={`/images/sycad_logo.png`} alt="Sénégal Flag" className="header-flag" />
        </Link>
        <div className="header-text">
          <h1>RÉPUBLIQUE DU SÉNÉGAL</h1>
          <h2>MINISTÈRE DE LA PÊCHE ET DE L’ÉCONOMIE MARITIME</h2>
        </div>
      </div>
      {user && <DynamicNavbar />}
    </header>
  );
};

export default Header;
