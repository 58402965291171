import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { envoyerRequeteApi } from './apis/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const GrNombreVentesParPeriode = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let query = `SELECT * FROM v_vente`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setData(response.datas);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const ventesParPeriode = data.reduce((acc, vente) => {
    const periode = new Date(vente.date_mouv).toLocaleDateString('fr-CA', { year: 'numeric', month: '2-digit' });
    acc[periode] = (acc[periode] || 0) + 1;
    return acc;
  }, {});

  const chartData = {
    labels: Object.keys(ventesParPeriode),
    datasets: [{
      label: 'Nombre de ventes par période',
      data: Object.values(ventesParPeriode),
      backgroundColor: 'rgba(153, 102, 255, 0.6)',
      fill: false,
      borderColor: 'rgba(153, 102, 255, 1)',
      tension: 0.1
    }],
  };

  return (
    <div>
      <h2>Nombre de ventes par période</h2>
      <div className="chart-container">
        <Line data={chartData} />
      </div>
    </div>
  );
};

export default GrNombreVentesParPeriode;
