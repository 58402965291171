import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { SycadContext } from '../contexts/SycadContext';
import '../components/styles/Navbar.css'; // Importez le fichier CSS

const DynamicNavbar = () => {
  const { user, logout } = useContext(SycadContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const renderNavLinks = () => {
    if (!user) return null;
    switch (user.code_groupe) {
      case 'DOUANE':
        return (
          <>
            <Nav.Link as={Link} to="/douane">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/demandes">Tickets</Nav.Link>
            <Nav.Link as={Link} to="/petroliers">Pétroliers</Nav.Link>
            <Nav.Link as={Link} to="/ventestations">Consommations</Nav.Link>
            <Nav.Link as={Link} to="/utilisateurs">Utilisateurs</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'STATION':
        return (
          <>
            <Nav.Link as={Link} to="/station">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/scanpay">Vendre</Nav.Link>
            <Nav.Link as={Link} to="/ventes">Ventes réalisées</Nav.Link>
            
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'POSTE':
       
        return (
          <>
            <Nav.Link as={Link} to="/poste">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/pirogues">Pirogues</Nav.Link>
            
            <Nav.Link as={Link} to="/ticketsrecus">Demandes</Nav.Link>
            <Nav.Link as={Link} to="/subventions">Subventions</Nav.Link>
            <Nav.Link as={Link} to="/activation">Pêcheurs</Nav.Link>
            <Nav.Link as={Link} to="/consommations">Consommations</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'PECHE':
        return (
          <>
            <Nav.Link as={Link} to="/peche">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/pirogues">Pirogues</Nav.Link>
            <Nav.Link as={Link} to="/demandes">Tickets</Nav.Link>
            <Nav.Link as={Link} to="/ticketsrecus">Demandes Poste</Nav.Link>
            <Nav.Link as={Link} to="/consommations">Consommations</Nav.Link>
            
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'PETROLIER':
        return (
          <>
            <Nav.Link as={Link} to="/petrolier">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/stations">Stations</Nav.Link>
            <Nav.Link as={Link} to="/ventestations">Ventes réalisées</Nav.Link>
            <Nav.Link as={Link} to="/utilisateurs">Utilisateurs</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'COMMISSION':
        return (
          <>
            <Nav.Link as={Link} to="/commission">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/demandes">Tickets</Nav.Link>
            <Nav.Link as={Link} to="/petroliers">Pétroliers</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      case 'SADMIN':
        return (
          <>
            <Nav.Link as={Link} to="/systeme">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/tickets">Tickets</Nav.Link>            
            <Nav.Link as={Link} to="/utilisateurs">Utilisateurs</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>

            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
      default:
        return (
          <>
            <Nav.Link as={Link} to="/admin_peche">Dashboard</Nav.Link>
            <Nav.Link as={Link} to="/demandes">Tickets</Nav.Link>
            <Nav.Link as={Link} to="/consommations">Consommations</Nav.Link>
            <Nav.Link as={Link} to="/list_pirogues">Pirogues</Nav.Link>
            
            <Nav.Link as={Link} to="/utilisateurs">Utilisateurs</Nav.Link>
            <Nav.Link as={Link} to="/compte">Mon compte</Nav.Link>
            <Nav.Link as="button" onClick={handleLogout}>Déconnexion</Nav.Link>
          </>
        );
    }
  };

  return (
    <div className="navbar-container">
      <Navbar expand="lg" className='navbar'>
        <Container>
          <Navbar.Brand as={Link} to="/login">SYCAD</Navbar.Brand>
          <Navbar.Toggle className='navbar-toggle-icon' aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {renderNavLinks()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      
        {user && (
          <p className='welcome'>Bienvenue {user.prenom_agent} {user.nom_agent}</p>
        )}
      </Navbar>
    </div>
  );
};

export default DynamicNavbar;
