import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import PopupBlocage from './PopupBlocage';

const ListBlocages = ({ pirogue, show, onHide, onSave }) => {
  const [blocages, setBlocages] = useState([]);
  const [showBlocagePopup, setShowBlocagePopup] = useState(false);

  const fetchBlocages = useCallback(async () => {
    try {
      const query = `SELECT * FROM liste_blocage WHERE id_pirogue = ${pirogue.id_pirogue}`;
      console.log('lister blocages : ',query);  
      const response = await envoyerRequeteApi(query, '');
      console.log('reponse liste blocages : ',response);
      setBlocages(response.datas);
    } catch (error) {
      console.error('Error fetching blocages', error);
    }
  }, [pirogue.id_pirogue]);  // Ajout de `pirogue.id_pirogue` comme dépendance

  useEffect(() => {
    if (pirogue && show) {
      fetchBlocages();
    }
  }, [pirogue, show, fetchBlocages]);  // Ajout de `fetchBlocages` comme dépendance

  return (
    <div>
      <h3>Historiques blocages : {pirogue.matricule}</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Motif</th>
            <th>Nom Agent</th>
          </tr>
        </thead>
        <tbody>
          {blocages.map((blocage, index) => (
            <tr key={index}>
              <td>{new Date(blocage.date_blocage).toLocaleString()}</td>
              <td>{blocage.nom_infraction}</td>
              <td>{blocage.motif}</td>
              <td>{blocage.bloquer?'Est bloquée':'Débloquée'}</td>
              <td>{blocage.nom_agent}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button
        variant={pirogue.bloquer ? 'success' : 'danger'}
        onClick={() => setShowBlocagePopup(true)}
      >
        {pirogue.bloquer ? 'Débloquer' : 'Bloquer'}
      </Button>

      <PopupBlocage
        show={showBlocagePopup}
        onHide={() => setShowBlocagePopup(false)}
        pirogue={pirogue}
        onSave={() => {
          fetchBlocages();
          onSave();  // Rafraîchir la liste des pirogues
        }}
      />
    </div>
  );
};

export default ListBlocages;
