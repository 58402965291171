// components/About.js
import React from 'react';
import { Container, Card} from 'react-bootstrap';
import Header from './Header';

const About = () => {
  return (
    <>  <Header />
    <Container className="mt-4">
      <h1 className="mb-4">À propos de SYCAD</h1>
     
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>SYCAD</Card.Title>
          <Card.Text>
            SYCAD est une plateforme informatique intégrée destinée à la gestion du carburant de pêche et sous douane.
            Elle appuie les politiques nationales pilotées par La Direction des Pêches Maritimes du Ministère de la Pêche.
          </Card.Text>
          <Card.Text>SYCAD oeuvre pour :</Card.Text>
          <ul>
            <li>Réduire des surcapacités</li>
            <li>Contrôler l’accès aux ressources</li>
            <li>Maîtrise de l’effort de pêche</li>
            <li>Renforcer le degré de responsabilisation des acteurs</li>
          </ul>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Acteurs impliqués</Card.Title>
          <ul>
            <li>Direction des Pêches Maritimes (DPM)</li>
            <li>Bureau des Hydrocarbures (DOUANE)</li>
            <li>Groupement des Pétroliers</li>
            <li>ICELABSOFT</li>
          </ul>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Objectif</Card.Title>
          <Card.Text>
            Nous rappelons les objectifs généraux de cette étude qui se veut la mise en œuvre d’un système de gestion permettant de :
          </Card.Text>
          <ul>
            <li>Dématérialiser la dotation des tickets de carburant pêche ;</li>
            <li>Contrôler régulièrement la consommation du carburant pêche par les services de la douane et de la pêche ;</li>
            <li>Disposer d’un levier efficace et efficient de suivi, contrôle et surveillance pour la pêche artisanale ;</li>
            <li>Maitriser et gérer la capacité de pêche artisanale ;</li>
            <li>Lutter contre la pêche INN.</li>
          </ul>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Principe</Card.Title>
          <Card.Text>
            Le ticket utilisé actuellement est obtenu chez les imprimeurs professionnels qui insèrent un numéro de série pour le décompte mais pas le retraçage encore moins la géolocalisation.
          </Card.Text>
          <Card.Text>
            Le ticket électronique ne requiert aucune impression, il agit comme un compte virtuel ou un crédit, qui est crédité ou débité en fonction des opérations sur la carte du pêcheur. En effet lorsque ce dernier reçoit sa dotation, sa carte est créditée, lorsqu’il achète son carburant sa carte est débitée.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Avantages : Chaque partie gagne</Card.Title>
          <h5>Les Pêcheurs :</h5>
          <ul>
            <li>Le ticket ne peut plus être perdu ou mouillé ou volé.</li>
            <li>Un code pin unique est relié à chaque carte de pirogue.</li>
            <li>La dotation se fait sans queue au niveau du poste.</li>
            <li>Le pêcheur peut librement se déplacer avec ses tickets après juste une déclaration.</li>
            <li>Si la carte est perdue, les tickets ne sont pas perdus.</li>
          </ul>
          <h5>Les Pétroliers :</h5>
          <ul>
            <li>Les rapports des pompes sont automatiques</li>
            <li>Ils sont disponibles par stations, par localités et par période</li>
            <li>Le suivi des quantités reçues et vendues est en temps réel</li>
          </ul>
          <h5>La DPM :</h5>
          <ul>
            <li>Contrôle national et total de l’activité de la pêche artisanale</li>
            <li>Données réelles par type de pêche, par localité, par période</li>
            <li>Communication améliorée avec les acteurs et instantanée</li>
          </ul>
          <h5>La Douane :</h5>
          <ul>
            <li>Suivi efficient de la consommation à la pompe</li>
            <li>Données réelles des consommations par stations, pétroliers</li>
            <li>Outil permettant d’organiser le dépotage</li>
          </ul>
        </Card.Body>
      </Card>
    </Container>
    </>
  );
};

export default About;
