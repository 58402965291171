import React, { useContext } from 'react';
import { SycadContext } from '../contexts/SycadContext';
import GrVentesParPostePeriodeRegion from './GrVentesParPostePeriodeRegion';
import GrVentesParPetrolierPeriodeRegion from './GrVentesParPetrolierPeriodeRegion';
import GrNombreVentesParPeriode from './GrNombreVentesParPeriode';
import Header from './Header';

const Dashboard = () => {
  const { user } = useContext(SycadContext);

  return (
    <>
      <Header user={user} />
      <div className="dashboard-container">
        {user.code_groupe === 'PECHE' || user.code_groupe === 'ADMIN' ? (
          <GrVentesParPostePeriodeRegion />
        ) : user.code_groupe === 'DOUANE' ? (
          <GrVentesParPetrolierPeriodeRegion />
        ) : user.code_groupe === 'STATION' ? (
          <GrNombreVentesParPeriode />
        ) : (
          <p>Accès non autorisé</p>
        )}
      </div>
    </>
  );
};

export default Dashboard;
