import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { Modal, Button, Form, FloatingLabel, Spinner, Toast } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';

const AddAgent = ({ show, onHide, agent, onSave, onRefresh }) => {
  const { user } = useContext(SycadContext);
  const initialFormData = useMemo(() => ({
    login_agent: '',
    hash_password_agent: '',
    nom_agent: '',
    prenom_agent: '',
    id_groupe: '',
    code_groupe: '',
    id_sgroupe: '',
    agent_mobile: false,
    gsm: '',
    code_imei: '',
    access_generic: true,
    bloquer: false,
    actif: false,
  }), []); 
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [groupes, setGroupes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sousGroupes, setSousGroupes] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isGroupeDisabled, setIsGroupeDisabled] = useState(false);
  const isAdministrator = user && ['DOUANE', 'PECHE', 'COMMISSION', 'SADMIN'].includes(user.code_groupe);

  const fetchCategories = useCallback(async (codeGroupe) => {
    try {
      const query = `SELECT id_groupe, btrim(libelle_groupe) as libelle_groupe FROM groupes 
      WHERE code_groupe = '${codeGroupe}' AND code_groupe != 'SADMIN'`;
      const response = await envoyerRequeteApi(query, '');
      setCategories(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  }, []);

  const fetchSousGroupes = useCallback(async (codeGroupe, currentUser) => {
    try {
      let query = '';
      if (codeGroupe === 'PECHEUR') {
        query = "SELECT id_pirogue AS id, btrim(matricule) AS name FROM liste_pirogues ORDER BY matricule";
      } else if (codeGroupe === 'PETROLIER') {
        if (currentUser && currentUser.code_groupe === 'PETROLIER') {
          // Restriction pour les utilisateurs PETROLIER
          query = `SELECT id_station AS id, btrim(concat(ls.nom_station, '_', nom_departement, ': ', nom_localite)) AS name
                   FROM liste_station ls
                   WHERE ls.id_petrolier = ${currentUser.id_sgroupe}
                   ORDER BY ls.nom_station, nom_departement`;
        } else {
          // Pour les administrateurs ou autres utilisateurs
          query = `SELECT id_petrolier AS id, btrim(nom_petrolier) AS name 
                   FROM liste_petroliers 
                   ORDER BY nom_petrolier`;
        }
      } else if (codeGroupe === 'POSTE') {
        query = "SELECT id_poste AS id, btrim(CONCAT(nom_departement, ': ', nom_poste)) AS name FROM liste_poste ORDER BY nom_departement, nom_poste";
      } else if (codeGroupe === 'STATION') {
        if (currentUser && currentUser.code_groupe === 'STATION') {
          // Restriction pour les utilisateurs STATION
          query = `SELECT id_station AS id, btrim(concat(ls.nom_station, '_', nom_departement, ': ', nom_localite)) AS name
                   FROM liste_station ls
                   WHERE ls.id_station = ${currentUser.id_sgroupe}
                   ORDER BY ls.nom_station`;
        } else if (currentUser && currentUser.code_groupe === 'PETROLIER') {
          // Restriction pour les utilisateurs PETROLIER visualisant les STATIONS
          query = `SELECT id_station AS id, btrim(concat(ls.nom_station, '_', nom_departement, ': ', nom_localite)) AS name
                   FROM liste_station ls
                   WHERE ls.id_petrolier = ${currentUser.id_sgroupe}
                   ORDER BY ls.nom_station`;
        } else {
          // Pour les administrateurs ou autres utilisateurs
          query = `SELECT id_station AS id, btrim(concat(ls.nom_station, '_', nom_departement, ': ', nom_localite)) AS name
                   FROM liste_station ls
                   ORDER BY ls.nom_station`;
        }
      }

      if (query) {
        const response = await envoyerRequeteApi(query, '');
        setSousGroupes(response.datas);
      } else {
        setSousGroupes([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-groupes:', error);
    }
  }, []);

  const resetForm = useCallback(() => {
    setFormData(initialFormData);
    setCategories([]);
    setSousGroupes([]);

    if (user) {
      setFormData(prevFormData => ({
        ...prevFormData,
        code_groupe: user.code_groupe
      }));
      fetchCategories(user.code_groupe);
      if (['PETROLIER', 'STATION'].includes(user.code_groupe)) {
        fetchSousGroupes(user.code_groupe, user);
      }
    }
  }, [user, fetchCategories, fetchSousGroupes, initialFormData]);

 
  const fetchGroupes = useCallback(async () => {
    try {
      const query = `SELECT DISTINCT btrim(code_groupe) as code_groupe FROM groupes WHERE code_groupe != 'SAMDIN' ORDER BY code_groupe`;
      const response = await envoyerRequeteApi(query, '');
      let filteredGroupes = response.datas;

      if (user) {
        if (user.code_groupe === 'PETROLIER') {
          filteredGroupes = filteredGroupes.filter(groupe => ['PETROLIER', 'STATION'].includes(groupe.code_groupe));
        } else if (user.code_groupe === 'STATION') {
          filteredGroupes = filteredGroupes.filter(groupe => groupe.code_groupe === 'STATION');
        } else if (user.code_groupe === 'DOUANE') {
          filteredGroupes = filteredGroupes.filter(groupe => groupe.code_groupe === 'DOUANE');
        }

        setFormData(prevFormData => ({
          ...prevFormData,
          code_groupe: user.code_groupe
        }));
        fetchCategories(user.code_groupe);
        if (['PETROLIER', 'STATION'].includes(user.code_groupe)) {
          fetchSousGroupes(user.code_groupe, user);
        }
      }
      setGroupes(filteredGroupes);
    } catch (error) {
      console.error('Erreur lors de la récupération des groupes:', error);
    }
  }, [user, fetchCategories, fetchSousGroupes]);

  useEffect(() => {
    fetchGroupes();
  }, [fetchGroupes]);

  useEffect(() => {
    if (show) {
      resetForm();
      if (agent) {
        setFormData({
          login_agent: agent.login_agent || '',
          hash_password_agent: agent.hash_password_agent || '',
          nom_agent: agent.nom_agent || '',
          prenom_agent: agent.prenom_agent || '',
          id_groupe: agent.id_groupe || '',
          code_groupe: agent.code_groupe || '',
          id_sgroupe: agent.id_sgroupe || '',
          agent_mobile: agent.agent_mobile || false,
          gsm: agent.gsm || '',
          code_imei: agent.code_imei || '',
          access_generic: agent.access_generic !== undefined ? agent.access_generic : true,
          bloquer: agent.bloquer || false,
          actif: agent.actif || false,
        });
        // Réinitialiser les catégories et sous-groupes basés sur le code_groupe de l'agent
        if (agent.code_groupe) {
          fetchCategories(agent.code_groupe);
          if (['PECHEUR', 'POSTE', 'STATION', 'PETROLIER'].includes(agent.code_groupe)) {
            fetchSousGroupes(agent.code_groupe, user);
          }
        }
      }
    }
  }, [show, agent, resetForm, fetchCategories, fetchSousGroupes, user]);

  useEffect(() => {
    if (formData.code_groupe) {
      fetchCategories(formData.code_groupe);
      if (['PECHEUR', 'POSTE', 'STATION', 'PETROLIER'].includes(formData.code_groupe)) {
        fetchSousGroupes(formData.code_groupe, user);
      } else {
        setSousGroupes([]);
      }
    } else {
      setCategories([]);
      setSousGroupes([]);
    }
  }, [formData.code_groupe, fetchCategories, fetchSousGroupes, user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleGroupeChange = (e) => {
    const selectedGroupe = e.target.value;
    setFormData(prevFormData => ({
      ...prevFormData,
      code_groupe: selectedGroupe,
      id_groupe: '',
      id_sgroupe: '',
    }));

    if (selectedGroupe === 'DOUANE') {
      fetchCategories(selectedGroupe);
      setIsGroupeDisabled(user.code_groupe === 'DOUANE');
    } else {
      setIsGroupeDisabled(false);
    }

    setCategories([]);
    setSousGroupes([]);

    if (['PECHEUR', 'POSTE', 'STATION', 'PETROLIER'].includes(selectedGroupe)) {
      fetchSousGroupes(selectedGroupe, user);
    }
  };
  const filteredGroupes = useMemo(() => {
    if (!user || !groupes) return [];
  
    let filtered;
    switch (user.code_groupe) {
      case 'ADMIN':
        filtered = groupes.filter(groupe => ![ 'STATION', 'DOUANE','SADMIN','COMMISSION','PECHEUR'].includes(groupe.code_groupe));
        console.log('Groupes filtrés pour ADMIN:', filtered.map(g => g.code_groupe));
        return filtered;
      case 'DOUANE':
        filtered = groupes.filter(groupe => ['DOUANE'].includes(groupe.code_groupe));
        console.log('Groupes filtrés pour DOUANE:', filtered.map(g => g.code_groupe));
        return filtered;
      case 'SADMIN':
          filtered = groupes.filter(groupe => !['STATION', 'DOUANE','SADMIN','PECHEUR'].includes(groupe.code_groupe));
          console.log('Groupes filtrés pour SADMIN:', filtered.map(g => g.code_groupe));
          return filtered;
      case 'PETROLIER':
        filtered = groupes.filter(groupe => ['STATION'].includes(groupe.code_groupe));
        console.log('Groupes filtrés pour PETROLIER:', filtered.map(g => g.code_groupe));
        return filtered;
      default:
        console.log('Groupes non filtrés pour', user.code_groupe, ':', groupes.map(g => g.code_groupe));
        return groupes;
    }
  }, [user, groupes]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm('Voulez-vous vraiment continuer ?')) {
      setLoading(true);
      try {
        const query = `
          SELECT add_edit_agent(
            ${agent ? agent.id_agent : 'NULL'},
            '${formData.login_agent}',
            '${formData.nom_agent}',
            '${formData.prenom_agent}',
            ${formData.id_groupe !== '' ? formData.id_groupe : 'NULL'},
            ${formData.actif},
            ${formData.id_sgroupe || 'NULL'},
            ${formData.agent_mobile},
            '${formData.gsm.replace(/'/g, "''")}',
            '${formData.code_imei.replace(/'/g, "''")}',
            ${formData.access_generic},
            ${formData.bloquer}
          );
        `;

        await envoyerRequeteApi(query, '');
        setToastMessage('Agent ajouté/modifié avec succès.');
        setShowToast(true);
        onSave();
        onHide();
        onRefresh();
      } catch (error) {
        console.error('Erreur lors de la sauvegarde de l\'agent:', error);
        setToastMessage('Erreur lors de la sauvegarde de l\'agent.');
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{agent ? 'Éditer' : 'Ajouter'} un agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <FloatingLabel controlId="login_agent" label="Login" className="mb-3">
              <Form.Control
                type="text"
                name="login_agent"
                value={formData.login_agent}
                onChange={handleChange}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId="nom_agent" label="Nom" className="mb-3">
              <Form.Control
                type="text"
                name="nom_agent"
                value={formData.nom_agent}
                onChange={handleChange}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId="prenom_agent" label="Prénom" className="mb-3">
              <Form.Control
                type="text"
                name="prenom_agent"
                value={formData.prenom_agent}
                onChange={handleChange}
                required
              />
            </FloatingLabel>
            <FloatingLabel controlId="code_groupe" label="Groupe" className="mb-3">
              <Form.Select
                name="code_groupe"
                value={formData.code_groupe}
                onChange={handleGroupeChange}
                required
                disabled={isGroupeDisabled}
              >
               <option value="">Sélectionnez un groupe</option>
                {filteredGroupes.map(groupe => (
                  <option key={groupe.code_groupe} value={groupe.code_groupe}>
                    {groupe.code_groupe}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="id_groupe" label="Catégorie" className="mb-3">
              <Form.Select
                name="id_groupe"
                value={formData.id_groupe}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez une catégorie</option>
                {categories.map(categorie => (
                  <option key={categorie.id_groupe} value={categorie.id_groupe}>{categorie.libelle_groupe}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel controlId="id_sgroupe" label="Sous-groupe" className="mb-3">
              <Form.Select
                name="id_sgroupe"
                value={formData.id_sgroupe}
                onChange={handleChange}
              >
                <option value="">Sélectionnez un sous-groupe</option>
                {sousGroupes.map(sg => (
                  <option key={sg.id} value={sg.id}>{sg.name}</option>
                ))}
              </Form.Select>
            </FloatingLabel>
            <Form.Check
              type="checkbox"
              id="agent_mobile"
              label="Agent mobile"
              name="agent_mobile"
              hidden={isAdministrator}
              checked={formData.agent_mobile}
              onChange={handleChange}
              className="mb-3"
            />
            <FloatingLabel controlId="gsm" hidden={isAdministrator} label="GSM" className="mb-3">
              <Form.Control
                type="text"
                hidden={isAdministrator}
                name="gsm"
                value={formData.gsm}
                onChange={handleChange}
              />
            </FloatingLabel>
            <FloatingLabel controlId="code_imei" hidden={isAdministrator} label="Code IMEI" className="mb-3">
              <Form.Control
                type="text"
                hidden={isAdministrator}
                name="code_imei"
                value={formData.code_imei}
                onChange={handleChange}
              />
            </FloatingLabel>
            <Form.Check
              type="checkbox"
              id="access_generic"
              label="Access generic"
              name="access_generic"
              checked={formData.access_generic}
              onChange={handleChange}
              hidden={isAdministrator}
              className="mb-3"
            />
            <Form.Check
              type="checkbox"
              id="bloquer"
              label="Bloquer"
              name="bloquer"
              hidden={isAdministrator}
              checked={formData.bloquer}
              onChange={handleChange}
              className="mb-3"
            />
            <Form.Check
              type="checkbox"
              id="actif"
              label="Actif"
              name="actif"
              hidden={isAdministrator}
              checked={formData.actif}
              onChange={handleChange}
              className="mb-3"
            />
             
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Enregistrer'}
            </Button>
          
          </Form>
        </Modal.Body>
      </Modal>

      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </>
  );
};

export default AddAgent;
