import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, Button, Table, Pagination, Toast, Modal, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import '../styles/DetailStation.css';

const DetailComposant = ({ station, onRetour, user }) => {
  const [activeTab, setActiveTab] = useState('infos');
  const [stationInfo, setStationInfo] = useState(null);
  const [demandes, setDemandes] = useState([]);
  const [ventes, setVentes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const isUserAuthorized = user && user.code_groupe === "PETROLIER";
  
  const [showModal, setShowModal] = useState(false); // État pour afficher le modal
  const [motif, setMotif] = useState(''); // État pour stocker le motif

  const showToastMessage = useCallback((message, variant) => {
    setToastMessage(message);
    setToastVariant(variant);
    setShowToast(true);
  }, []);

  const fetchStationInfo = useCallback(async () => {
    const query = `SELECT * FROM liste_station WHERE id_station=${station.id_station}`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setStationInfo(response.datas[0]);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de la station:', error);
      showToastMessage('Erreur lors de la récupération des informations', 'danger');
    }
  }, [station.id_station, showToastMessage]);

  const fetchDemandes = useCallback(async () => {
    const query = `SELECT * FROM demande_depot WHERE id_station=${station.id_station}`;
    console.log('Chargement des demandes:', query);
    try {
      const response = await envoyerRequeteApi(query, '');
      setDemandes(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
      showToastMessage('Erreur lors de la récupération des demandes', 'danger');
    }
  }, [station.id_station, showToastMessage]);

  const fetchVentes = useCallback(async () => {
    const query = `SELECT id_station, count(qte) as count, date_mouv, SUM(qte) as total_qte 
    FROM v_vente WHERE id_station=${station.id_station} GROUP BY date_mouv, id_station`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setVentes(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des ventes:', error);
      showToastMessage('Erreur lors de la récupération des ventes', 'danger');
    }
  }, [station.id_station, showToastMessage]);

  const handleDebloquer = async () => {
    if (stationInfo.bloquer) {
      // Si la station est déjà bloquée, la débloquer
      const query = `UPDATE stations SET bloquer = false, motif_blocage = 'SITUATION REGLEE' WHERE id_station=${station.id_station}`;
      try {
        await envoyerRequeteApi(query, '');
        showToastMessage('Station débloquée avec succès', 'success');
        fetchStationInfo(); // Recharger les informations de la station
      } catch (error) {
        console.error('Erreur lors du déblocage de la station:', error);
        showToastMessage('Erreur lors du déblocage de la station', 'danger');
      }
    } else {
      // Si la station n'est pas bloquée, demander le motif et bloquer
      setShowModal(true);
    }
  };

  const handleConfirmBloquer = async () => {
    if (motif) {
      const query = `UPDATE stations SET bloquer = true, motif_blocage = '${motif}' WHERE id_station=${station.id_station}`;
      try {
        await envoyerRequeteApi(query, '');
        showToastMessage('Station bloquée avec succès', 'success');
        fetchStationInfo(); // Recharger les informations de la station
      } catch (error) {
        console.error('Erreur lors du blocage de la station:', error);
        showToastMessage('Erreur lors du blocage de la station', 'danger');
      }
      setShowModal(false); // Fermer le modal après confirmation
    }
  };

  useEffect(() => {
    fetchStationInfo();
    fetchDemandes();
    fetchVentes();
  }, [fetchStationInfo, fetchDemandes, fetchVentes]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDemandes = demandes.slice(indexOfFirstItem, indexOfLastItem);
  const currentVentes = ventes.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="detail-station-container">
      <h2>{station.nom_station}</h2>
      <Tabs
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-3"
      >
        <Tab eventKey="infos" title="Infos">
          {stationInfo && (
            <div className="station-info">
              <p><strong>Code station:</strong> {stationInfo.code_station}</p>
              <p><strong>Adresse:</strong> {stationInfo.adresse}</p>
              <p><strong>Gérant:</strong> {stationInfo.nom_gerant}</p>
              <Button 
                className={`bouton-bloquer ${stationInfo.bloquer ? "bouton-debloquer" : ""}`} 
                onClick={handleDebloquer}
                hidden={isUserAuthorized}
              >
                {stationInfo.bloquer ? "Débloquer" : "Bloquer"}
              </Button>
              <p><strong>Téléphone:</strong> {stationInfo.telephone}</p>
            </div>
          )}
        </Tab>
        <Tab eventKey="demandes" title="Demandes">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>RefDemande</th>
                <th>Date</th>
                <th>RefDouane</th>
                <th>Qté</th>
              </tr>
            </thead>
            <tbody>
              {currentDemandes.map((demande, index) => (
                <tr key={index}>
                  <td>{demande.ref_demande}</td>
                  <td>{demande.date_demande_depo}</td>
                  <td>{demande.ref_validation}</td>
                  <td>{demande.qte_demande}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(Math.ceil(demandes.length / itemsPerPage)).keys()].map((number) => (
              <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Tab>
        <Tab eventKey="ventes" title="Ventes">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Quantité</th>
              </tr>
            </thead>
            <tbody>
              {currentVentes.map((vente, index) => (
                <tr key={index}>
                  <td>{vente.date_mouv}</td>
                  <td>{vente.total_qte}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination>
            {[...Array(Math.ceil(ventes.length / itemsPerPage)).keys()].map((number) => (
              <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                {number + 1}
              </Pagination.Item>
            ))}
          </Pagination>
          <p><strong>Quantité totale vendue:</strong> {ventes.reduce((total, vente) => total + parseFloat(vente.total_qte), 0)}</p>
        </Tab>
      </Tabs>
      <Button variant="primary" onClick={onRetour} className="mt-3">
        Retour
      </Button>

      {/* Modal pour saisir le motif de blocage */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Saisir le motif de blocage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formMotif">
            <Form.Label>Motif</Form.Label>
            <Form.Control
              type="text"
              placeholder="Entrez le motif du blocage"
              value={motif}
              onChange={(e) => setMotif(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirmBloquer}>
            Confirmer le blocage
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          top: 20,
          right: 20,
          zIndex: 9999
        }}
        bg={toastVariant}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </div>
  );
};

export default DetailComposant;
