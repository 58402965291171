import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';

const AffecterPirogue = ({ show, onHide, pirogue, onSave }) => {
  const [ports, setPorts] = useState([]);
  const [selectedPort, setSelectedPort] = useState('');

  useEffect(() => {
    const fetchPorts = async () => {
      try {
        const query = `SELECT DISTINCT btrim(a_port) as a_port, nom_port, btrim(code_region) as code_region FROM liste_poste ORDER BY nom_port`;
        console.log('fetch ports : ',query);
        const response = await envoyerRequeteApi(query, '');
        console.log('response ports : ',response);
        setPorts(response.datas);
        
      } catch (error) {
        console.error('Error fetching ports', error);
      }
    };

    fetchPorts();
  }, []);

  const handleAffecter = async () => {
    try {
      const query = `UPDATE pirogues SET a_port = '${selectedPort}' WHERE id_pirogue = ${pirogue.id_pirogue} RETURNING matricule`;
      console.log('affecter pirogue : ',query);
      const result = await envoyerRequeteApi(query, '');

      if (result.datas[0] && result.datas[0].matricule === pirogue.matricule) {
        alert(`Pirogue ${pirogue.matricule} affectée avec succès au port ${selectedPort}`);
        onSave(); // Rafraîchir la liste des pirogues
        onHide(); // Fermer le popup
      } else {
        alert('Erreur lors de l\'affectation de la pirogue.');
      }
    } catch (error) {
      console.error('Error affecting pirogue', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Affecter la Pirogue {pirogue?.matricule}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPortActuel">
            <Form.Label>Port Actuel</Form.Label>
            <Form.Control type="text" value={pirogue?.a_port} readOnly />
          </Form.Group>
          <Form.Group controlId="formPortAffecte">
            <Form.Label>Port Affecté</Form.Label>
            <Form.Control
              as="select"
              value={selectedPort}
              onChange={(e) => setSelectedPort(e.target.value)}
            >
              <option value="">Sélectionnez un port</option>
              {ports.map((port) => (
                <option key={port.a_port} value={port.a_port}>
                  {port.nom_port}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleAffecter}>
          Affecter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AffecterPirogue;
