import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Toast, Spinner, Pagination } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddAgent from './AddAgent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faKeyboard, faUsers } from '@fortawesome/free-solid-svg-icons';
import '../styles/ListUtilisateurs.css';

const ListUtilisateurs = () => {
  const { user } = useContext(SycadContext);
  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [groupes, setGroupes] = useState([]);
  const [selectedGroupe, setSelectedGroupe] = useState('');
  const [nomAgent, setNomAgent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [showAddAgentModal, setShowAddAgentModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const agentsPerPage = 150;

  const fetchGroupes = useCallback(async () => {
    let query = `SELECT DISTINCT btrim(gr.code_groupe) AS code_groupe 
                FROM groupes gr
                WHERE code_groupe != 'SAMDIN'  
                ORDER BY code_groupe`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setGroupes(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des groupes:', error);
    }
  }, []);

  const fetchAgents = useCallback(async () => {
    setShowToast(true);

    let query = '';

    if (user) {
      switch (user.code_groupe) {
        case 'PETROLIER':
          query = `
            SELECT *
            FROM connectemoi
            WHERE (id_sgroupe = ${user.id_sgroupe} AND code_groupe = 'PETROLIER') 
              OR (id_sgroupe IN (SELECT id_station FROM stations s WHERE s.id_petrolier = ${user.id_sgroupe})) 
              AND (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
              AND code_groupe LIKE '%${selectedGroupe}%'
              AND code_groupe != 'SAMDIN'  
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
        case 'DOUANE':
          query = `
            SELECT *
            FROM connectemoi
            WHERE code_groupe = 'DOUANE'
              AND (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
              AND code_groupe LIKE '%${selectedGroupe}%'
              AND code_groupe != 'SAMDIN'  
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
        case 'PECHE':
          query = `
            SELECT *
            FROM connectemoi
            WHERE code_groupe = 'PECHE'
              AND (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
              AND code_groupe LIKE '%${selectedGroupe}%'
              AND code_groupe != 'SAMDIN'
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
        case 'ADMIN':
          query = `
            SELECT *
            FROM connectemoi
            WHERE code_groupe != 'SADMIN'
              AND (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
              AND code_groupe LIKE '%${selectedGroupe}%'
              AND code_groupe != 'SAMDIN'
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
        case 'SADMIN':
          query = `
            SELECT *
            FROM connectemoi
            WHERE (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
              AND code_groupe LIKE '%${selectedGroupe}%'
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
        default:
          query = `
            SELECT *
            FROM connectemoi
            WHERE code_groupe LIKE '%${selectedGroupe}%'
              AND (nom_agent ILIKE '%${nomAgent}%' OR prenom_agent ILIKE '%${nomAgent}%')
            LIMIT ${agentsPerPage}
            OFFSET ${(currentPage - 1) * agentsPerPage}`;
          break;
      }
    }

    try {
      console.log('Requête pour lister les agents:', query);  // Ajout du log
      const response = await envoyerRequeteApi(query, '');
      setAgents(response.datas);
      setFilteredAgents(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des agents:', error);
    } finally {
      setShowToast(false);
    }
  }, [user, selectedGroupe, nomAgent, currentPage]);

  useEffect(() => {
    fetchGroupes();
    fetchAgents();
  }, [fetchGroupes, fetchAgents]);

  const applyFilters = useCallback(() => {
    let filtered = agents;

    if (selectedGroupe) {
      filtered = filtered.filter(agent => agent.code_groupe === selectedGroupe);
    }
    if (nomAgent) {
      filtered = filtered.filter(agent =>
        agent.nom_agent.toLowerCase().includes(nomAgent.toLowerCase()) ||
        agent.prenom_agent.toLowerCase().includes(nomAgent.toLowerCase())
      );
    }

    setFilteredAgents(filtered);
  }, [agents, selectedGroupe, nomAgent]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleAddNewAgent = () => {
    setSelectedAgent(null);
    setShowAddAgentModal(true);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (id) => {
    // Ajouter la logique pour supprimer un agent
    console.log(`Supprimer l'agent avec l'ID: ${id}`);
  };

  const handleBlock = (id) => {
    // Ajouter la logique pour bloquer un agent
    console.log(`Bloquer l'agent avec l'ID: ${id}`);
  };

  const handleEdit = (agent) => {
    setSelectedAgent(agent);
    setShowAddAgentModal(true);
  };

  const handleSave = () => {
    setShowAddAgentModal(false);
    fetchAgents();
  };

  const handleRefresh = () => {
    fetchAgents();
  };

  const indexOfLastAgent = currentPage * agentsPerPage;
  const indexOfFirstAgent = indexOfLastAgent - agentsPerPage;
  const currentAgents = filteredAgents.slice(indexOfFirstAgent, indexOfLastAgent);

  const totalPages = Math.ceil(filteredAgents.length / agentsPerPage);

  // Déterminer si le bouton "Nouveau agent" doit être désactivé
  const disableNewAgentButton = user && ['PECHEUR', 'POSTE', 'STATION', 'COMMISSION', 'PECHE'].includes(user.code_groupe);

  return (
    <>
      <Header user={user} />
      <div className="container-list-utilisateurs">
        <h2>Liste des utilisateurs</h2>
       
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="groupeSelect">Groupe</label>
            <select
              id="groupeSelect"
              value={selectedGroupe}
              onChange={(e) => setSelectedGroupe(e.target.value)}
            >
             <option value="">Tous les groupes</option>
            {groupes
              .filter(groupe => groupe.code_groupe !== 'SADMIN')
              .map((groupe, index) => (
                <option key={index} value={groupe.code_groupe}>
                  {groupe.code_groupe}
                </option>
              
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="nomAgentFilter">Nom de l'agent</label>
            <input
              type="text"
              id="nomAgentFilter"
              value={nomAgent}
              onChange={(e) => setNomAgent(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button className="rounded-button btn-primary" onClick={applyFilters}>
              Filtrer
            </button>
          </div>
        </div>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Nombre d'agents affichés: {filteredAgents.length}</strong>
          <button
          className="bouton-nouveau"
          onClick={handleAddNewAgent}
          disabled={disableNewAgentButton}
        >
          Nouveau agent
        </button>
        </div>
    
        <div className="utilisateurs-container">
          {currentAgents.map((agent) => (
            <div key={agent.id_agent} className="utilisateur-card">
              <img className="utilisateur-img" src={`${process.env.PUBLIC_URL}/images/userprofil.png`} alt="User Icon" />
              <h3>{agent.nom_agent} {agent.prenom_agent}</h3>
              <p><FontAwesomeIcon icon={faUserTie} /> <strong>Profil:</strong> {agent.libelle_groupe}</p>
              <p><FontAwesomeIcon icon={faKeyboard} /> <strong>Login:</strong> {agent.login_agent}</p>
              <p><FontAwesomeIcon icon={faUsers} /> <strong>Groupe:</strong> {agent.code_groupe}</p>
              <div className="button-group">
                <button className="rounded-button btn-primary" onClick={() => handleEdit(agent)}>Editer</button>
                <button className="rounded-button btn-warning" onClick={() => handleBlock(agent.id_agent)}>Bloquer</button>
                <button className="rounded-button btn-danger" onClick={() => handleDelete(agent.id_agent)}>Supprimer</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Chargement</strong>
        </Toast.Header>
        <Toast.Body>
          <Spinner animation="border" size="sm" /> Veuillez patienter pendant le chargement des utilisateurs.
        </Toast.Body>
      </Toast>
      <AddAgent 
        show={showAddAgentModal} 
        onHide={() => setShowAddAgentModal(false)} 
        agent={selectedAgent} 
        onSave={handleSave} 
        onRefresh={handleRefresh} 
      />
    </>

  );
};

export default ListUtilisateurs;
