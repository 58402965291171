import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Modal, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';

const AddStation = ({ show, onHide, station, onSave, onShowToast }) => {
  const { user } = useContext(SycadContext);
  const [formData, setFormData] = useState({
    nom_station: '',
    adresse: '',
    telephone: '',
    nom_gerant: '',
    id_localite: '',
    id_petrolier: user.code_groupe === 'PETROLIER' ? user.id_sgroupe : '',
    actif: true,
    code_station: ''
  });
  const [loading, setLoading] = useState(false);
  const [localites, setLocalites] = useState([]);
  const [petroliers, setPetroliers] = useState([]);

  // Fonction pour réinitialiser le formulaire
  // Remplir le formulaire avec les données de la station lors de l'édition
  useEffect(() => {
    const resetForm = () => {
      setFormData({
        nom_station: '',
        adresse: '',
        telephone: '',
        nom_gerant: '',
        id_localite: '',
        id_petrolier: user.code_groupe === 'PETROLIER' ? user.id_sgroupe : '',
        actif: true,
        code_station: ''
      });
    };
  
    if (show) {
      if (station) {
        setFormData({
          nom_station: station.nom_station || '',
          adresse: station.adresse || '',
          telephone: station.telephone || '',
          nom_gerant: station.nom_gerant || '',
          id_localite: station.id_localite || '',
          id_petrolier: station.id_petrolier || '',
          actif: station.actif || true,
          code_station: station.code_station || ''
        });
      } else {
        resetForm();  // Vider les champs pour l'ajout d'une nouvelle station
      }
    }
  }, [station, show, user.code_groupe, user.id_sgroupe]);
  
  const fetchLocalites = useCallback(async () => {
    let query = `SELECT id_localite, concat(nom_departement, ': ',nom_localite) as nom_localite FROM liste_localites ORDER BY nom_departement,nom_localite`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setLocalites(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des localités:', error);
    }
  }, []);

  const fetchPetroliers = useCallback(async () => {
    let query = `SELECT id_petrolier, nom_petrolier FROM petrolier ORDER BY nom_petrolier`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setPetroliers(response.datas);
    } catch (error) {
      console.error('Erreur lors de la récupération des pétroliers:', error);
    }
  }, []);

  useEffect(() => {
    fetchLocalites();
    fetchPetroliers();
  }, [fetchLocalites, fetchPetroliers]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const action = station ? 'modifier' : 'ajouter';
    if (window.confirm(`Voulez-vous vraiment ${action} cette station ?`)) {
      setLoading(true);
      try {
        let query = '';
        if (station) {
          // Requête pour la modification de la station existante
          query = `
            UPDATE stations
            SET nom_station = '${formData.nom_station}',            
                adresse = '${formData.adresse}',
                telephone = '${formData.telephone}',
                nom_gerant = '${formData.nom_gerant}',
                id_localite = ${formData.id_localite}
            WHERE code_station = '${formData.code_station}'
            RETURNING id_station;
          `;
        } else {
          // Requête pour l'ajout d'une nouvelle station
          query = `
            SELECT add_station(
              '${formData.nom_station}',            
              '${formData.adresse}',
              '${formData.telephone}',
              '${formData.nom_gerant}',
              ${formData.id_localite},
              ${user.code_groupe === 'PETROLIER' ? user.id_sgroupe : formData.id_petrolier},
              ${formData.actif},
              false,
              'RAS',
              '${user.login_agent}'
            ) as result;
          `;
        }
  
        console.log('Requête pour la station:', query);
        const response = await envoyerRequeteApi(query, '');
        let newIdStation = 0;
        console.log('Reponse: ', response.datas);
        if (action !== 'ajouter') {
          newIdStation = response.datas[0].id_station;
        }
        else {
          newIdStation = response.datas[0].result;
        }

        if (response && newIdStation > 0) {
          onShowToast(`Station ${station ? 'modifiée' : 'ajoutée'} avec succès.`, 'success');
          onSave();
          onHide();
        } else if (response.datas && newIdStation === 0) {
          onShowToast('Une station avec ce code existe déjà.', 'warning');
        } else {
          onShowToast(`Erreur lors de ${station ? 'la modification' : 'l\'ajout'} de la station.`, 'error');
        }
      } catch (error) {
        console.error(`Erreur lors de ${station ? 'la modification' : 'l\'ajout'} de la station:`, error);
        onShowToast(`Erreur lors de ${station ? 'la modification' : 'l\'ajout'} de la station.`, 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{station ? 'Modifier' : 'Ajouter'} une station</Modal.Title>
        </Modal.Header>
    
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <FloatingLabel controlId="nom_station" label="Nom de la station" className="mb-3">
              <Form.Control
                type="text"
                name="nom_station"
                value={formData.nom_station}
                onChange={handleChange}
                required
              />
            </FloatingLabel>

            <FloatingLabel controlId="code_station" label="Code de la station" className="mb-3">
              <Form.Control
                type="text"
                name="code_station"
                value={formData.code_station}
                onChange={handleChange}
                placeholder="code de la station"
                disabled={station} // Désactiver le champ si c'est une modification
              />
            </FloatingLabel>

            <FloatingLabel controlId="adresse" label="Adresse" className="mb-3">
              <Form.Control
                type="text"
                name="adresse"
                value={formData.adresse}
                onChange={handleChange}
              />
            </FloatingLabel>

            <FloatingLabel controlId="telephone" label="Téléphone" className="mb-3">
              <Form.Control
                type="text"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                required
              />
            </FloatingLabel>

            <FloatingLabel controlId="nom_gerant" label="Nom du gérant" className="mb-3">
              <Form.Control
                type="text"
                name="nom_gerant"
                value={formData.nom_gerant}
                onChange={handleChange}
                required
              />
            </FloatingLabel>

            <FloatingLabel controlId="id_localite" label="Localité" className="mb-3">
              <Form.Select
                name="id_localite"
                value={formData.id_localite}
                onChange={handleChange}
                required
              >
                <option value="">Sélectionnez une localité</option>
                {localites.map(localite => (
                  <option key={localite.id_localite} value={localite.id_localite}>
                    {localite.nom_localite}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel controlId="id_petrolier" label="Pétrolier" className="mb-3">
              <Form.Select
                name="id_petrolier"               
                value={user && user.code_groupe === 'PETROLIER' ? user.id_sgroupe : formData.id_petrolier}
                onChange={handleChange}
                disabled={user && user.code_groupe === 'PETROLIER'}
                required
              >
                <option value="">Sélectionnez un pétrolier</option>
                {petroliers.map(petrolier => (
                  <option key={petrolier.id_petrolier} value={petrolier.id_petrolier}>
                    {petrolier.nom_petrolier}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>

            <Form.Check
              type="checkbox"
              id="actif"
              label="Actif"
              name="actif"
              checked={formData.actif}
              onChange={handleChange}
              className="mb-3"
            />

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Enregistrer'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddStation;
