import React, { useContext } from 'react';
import Layout from '../Layout';
import { SycadContext } from '../../contexts/SycadContext';

const PetrolierHome = () => {
  const { user } = useContext(SycadContext);
  if (!user) return null;
  return (
    <Layout>
      <h2>Bienvenue, Administrateur </h2>
      <p>Ceci est votre tableau de bord Petrolier X.</p>
    </Layout>
  );
};

export default PetrolierHome;
