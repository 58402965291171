import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import { SycadProvider } from './contexts/SycadContext';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import SessionTimeout from './contexts/SessionTimeout';
import ListPirogues from './components/Admin/AdminPirogue';
import AdminHome from './components/Dashboard';
import SystemeHome from './components/Admin/SystemeHome';
import StationHome from './components/Admin/StationHome';
import PosteHome from './components/Admin/PosteHome';
import PecheHome from './components/Dashboard';
import DouaneHome from './components/Dashboard';
import CommissionHome from './components/Admin/CommissionHome';
import ListSubventionPoste from './components/Admin/ListSubventionPoste';
import ListSubventions from './components/Admin/ListSubventions';
import ListUtilisateurs  from './components/Admin/ListUtilisateurs';
import MajPecheurEtPin from './components/Admin/MajPecheurEtPin';
import PetrolierHome from './components/Admin/PetrolierHome';
import './components/styles/styles.css';

import ListDemande from './components/Admin/ListDemande'; 
import Pirogues from './components/Admin/Pirogues';

import ListVentes from './components/Admin/ListVentes';
import ListConsommations from './components/Admin/ListConsommations';

import ListPetroliers from './components/Admin/ListPetroliers';
import ListStations from './components/Admin/ListStations';
import ListVentesStations from './components/Admin/ListVentesStations';
import Compte from './components/Admin/MonCompte';
import About from './components/About';

import ScanPay from './components/Admin/ScanPay';

function App() {
  return (
   
    <AuthProvider>
      <SycadProvider>
        <Router>
          <SessionTimeout timeout={180000} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/code" element={<MajPecheurEtPin />} />

            {/* Routes protégées */}
            <Route element={<ProtectedRoute />}>
              <Route path="/admin_peche" element={<AdminHome />} />
              <Route path="/list_pirogues" element={<ListPirogues />} />
              <Route path="/admin" element={<AdminHome />} />
              <Route path="/systeme" element={<SystemeHome />} />
              <Route path="/station" element={<StationHome />} />
              <Route path="/ticketsrecus" element={<ListSubventionPoste />} />
              <Route path="/poste" element={<PosteHome />} />
              <Route path="/petrolier" element={<PetrolierHome />} />
              <Route path="/activation" element={<MajPecheurEtPin />} />
              <Route path="/peche" element={<PecheHome />} />
              <Route path="/douane" element={<DouaneHome />} />
              <Route path="/commission" element={<CommissionHome />} />
              <Route path="/pirogues" element={<Pirogues />} />
              <Route path="/consommations" element={<ListConsommations />} />
              <Route path="/ventestations" element={<ListVentesStations />} />
              <Route path="/ventes" element={<ListVentes />} />
              <Route path="/scanpay" element={<ScanPay />} />
              <Route path="/tickets" element={<ListDemande />} />
              <Route path="/demandes" element={<ListDemande />} />
              <Route path="/subventions" element={<ListSubventions />} />
              <Route path="/utilisateurs" element={<ListUtilisateurs />} />
              <Route path="/stations" element={<ListStations />} />
              <Route path="/petroliers" element={<ListPetroliers />} />
              <Route path="/compte" element={<Compte />} />
            </Route>
          </Routes>
        </Router>
      </SycadProvider>
    </AuthProvider>
  );
}

export default App;