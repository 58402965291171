import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import './styles/Home.css';
import Footer from './Footer';

const Home = () => {
  const images = [
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond1.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond2.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond3.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond5.webp`,
    `${process.env.PUBLIC_URL}/images/bannieres/image_fond4.webp`,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true); // Contrôle de l'animation de fondu

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Lancer la sortie en fondu

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length); // Changer d'image après l'animation de disparition
        setFade(true); // Relancer l'entrée en fondu
      }, 1000); // Durée de l'effet de fondu (1 seconde)

    }, 6000); // Changer d'image toutes les 6 secondes (incluant 1 seconde pour la transition)

    return () => clearInterval(interval); // Nettoyer l'intervalle à la fin du composant
  }, [images.length]);

  return (
    <div className={`home-background ${fade ? 'fade-in' : 'fade-out'}`} style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <header className="home-header">
        <h1>Bienvenue sur SYCAD</h1>
        <br></br>
        <p>Système de gestion du carburant sous douane</p>
      </header>
      
      {/* Bouton pour ouvrir la page Code en haut à droite */}
      <div className="btnCode">
        <Button href="/code" variant="secondary">
          Activation
        </Button>
      </div>

      <Container className="home-content text-center">
        <div className="btnConnexion">
          <Button href="/login">Entrer</Button>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Home;
