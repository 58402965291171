import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Toast, Pagination, Modal, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddEditStation from './AddStation';
import DetailComposant from './DetailComposant'; 
import '../styles/Stations.css';

const ListStations = () => {
  const { user } = useContext(SycadContext);
  const [stations, setStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [stationNameFilter, setStationNameFilter] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [currentPage, setCurrentPage] = useState(1);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showStationsModal, setShowStationsModal] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);
  const [stationsDetails, setStationsDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false); 

  const [regions, setRegions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [petroliers, setPetroliers] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedPetrolierFilter, setSelectedPetrolierFilter] = useState('');

  const stationsPerPage = 20;

  const fetchStations = useCallback(async () => {
    setShowToast(true);
    let query = `SELECT * FROM liste_station WHERE id_station IS NOT NULL`;
    if (user.code_groupe === 'PETROLIER') {
      query += ` AND id_petrolier = ${user.id_sgroupe}`;
    }
    query += ` ORDER BY nom_station`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setStations(response.datas);
      setFilteredStations(response.datas);
    } catch (error) {
      console.error('Error fetching stations', error);
    } finally {
      setShowToast(false);
    }
  }, [user]);

  const handleShowToast = (message, variant = 'success') => {
    setToastMessage(message);
    setToastVariant(variant);
    setShowToast(true);
  };

  const fetchRegions = useCallback(async () => {
    const query = `SELECT DISTINCT code_region, code_region as nom_region FROM liste_station ORDER BY code_region`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setRegions(response.datas);
    } catch (error) {
      console.error('Error fetching regions', error);
    }
  }, []);

  const fetchDepartments = useCallback(async (region) => {
    let query = `SELECT DISTINCT nom_departement FROM liste_station`;
    if (region) {
      query += ` WHERE code_region = '${region}'`;
    }
    query += ` ORDER BY nom_departement`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setDepartments(response.datas);
    } catch (error) {
      console.error('Error fetching departments', error);
    }
  }, []);

  const fetchPetroliers = useCallback(async (region, department) => {
    let query = `SELECT DISTINCT id_petrolier, nom_petrolier FROM liste_station`;
    let conditions = [];
    if (region) conditions.push(`code_region = '${region}'`);
    if (department) conditions.push(`nom_departement = '${department}'`);
    if (conditions.length > 0) {
      query += ` WHERE ${conditions.join(' AND ')}`;
    }
    query += ` ORDER BY nom_petrolier`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setPetroliers(response.datas);
    } catch (error) {
      console.error('Error fetching petroliers', error);
    }
  }, []);

  useEffect(() => {
    fetchStations();
    fetchRegions();
  }, [fetchStations, fetchRegions]);

  useEffect(() => {
    fetchDepartments(selectedRegion);
  }, [selectedRegion, fetchDepartments]);

  useEffect(() => {
    fetchPetroliers(selectedRegion, selectedDepartment);
  }, [selectedRegion, selectedDepartment, fetchPetroliers]);

  const applyFilters = useCallback(() => {
    setFilteredStations([]);
    let filtered = stations;

    if (stationNameFilter) {
      filtered = filtered.filter(station => 
        station.nom_station.toLowerCase().includes(stationNameFilter.toLowerCase())
      );
    }
    if (selectedRegion) {
      filtered = filtered.filter(station => station.code_region === selectedRegion);
    }
    if (selectedDepartment) {
      filtered = filtered.filter(station => station.nom_departement === selectedDepartment);
    }
    if (selectedPetrolierFilter) {
      filtered = filtered.filter(station => station.id_petrolier === selectedPetrolierFilter);
    }

    setFilteredStations(filtered);
    setCurrentPage(1);
  }, [stations, stationNameFilter, selectedRegion, selectedDepartment, selectedPetrolierFilter]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddStation = () => {
    setSelectedStation(null);
    setShowAddEditModal(true);
  };

  const handleEditStation = (station) => {
    setSelectedStation(station);
    setShowAddEditModal(true);
  };

  const handleSave = () => {
    setShowAddEditModal(false);
    fetchStations();
  };

  const handleShowStationDetails = async (station) => {
    const query = `
      SELECT 
        concat(nom_departement, ': ', nom_localite) as nom_station, 
        nom_station, 
        coalesce((select sum(qte) from carbu_mouv cm where code_station=ls.code_station), 0) as tot_ticket_vendu ,
        coalesce((select sum(qte_demande) from demande_depot cm where id_station=ls.id_station), 0) as tot_ticket_recu 
      FROM liste_station ls 
      WHERE id_station=${station.id_station}`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setStationsDetails(response.datas);
      setSelectedStation(station); 
      setShowDetails(true); 
    } catch (error) {
      console.error('Error fetching station details', error);
    }
  };

  const handleDeleteStation = async (station) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette station ? Cette action est irréversible.')) {
      try {
        const query = `SELECT del_station(${station.id_station}) AS result;`;
        const response = await envoyerRequeteApi(query, '');
        const result = response.datas[0].result;
  
        if (result.code === "200") {
          handleShowToast('Station supprimée avec succès.', 'success');
          fetchStations(); // Réafficher la liste des stations après suppression
        } else {
          handleShowToast(result.donnee || 'Erreur lors de la suppression de la station.', 'danger');
        }
      } catch (error) {
        handleShowToast('Erreur lors de la suppression de la station.', 'danger');
      }
    }
  };

  const indexOfLastStation = currentPage * stationsPerPage;
  const indexOfFirstStation = indexOfLastStation - stationsPerPage;
  const currentStations = filteredStations.slice(indexOfFirstStation, indexOfLastStation);

  const totalPages = Math.ceil(filteredStations.length / stationsPerPage);

  const canAddStation = ['SADMIN', 'PETROLIER'].includes(user.code_groupe);

  return (
    <>
      <Header user={user} />
      
      {!showDetails ? (
        <div className="container-list-stations">
          <h2>Liste des stations</h2>
          
          <div className="form-container">
            <div className="form-group">
              <label htmlFor="stationNameFilter">Nom de la station</label>
              <input
                type="text"
                id="stationNameFilter"
                value={stationNameFilter}
                onChange={(e) => setStationNameFilter(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="regionFilter">Région</label>
              <select
                id="regionFilter"
                value={selectedRegion}
                onChange={(e) => {
                  setSelectedRegion(e.target.value);
                  setSelectedDepartment('');
                  setSelectedPetrolierFilter('');
                }}
              >
                <option value="">Toutes les régions</option>
                {regions.map((region) => (
                  <option key={region.code_region} value={region.code_region}>{region.nom_region}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="departmentFilter">Département</label>
              <select
                id="departmentFilter"
                value={selectedDepartment}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                  setSelectedPetrolierFilter('');
                }}
              >
                <option value="">Tous les départements</option>
                {departments.map((dept) => (
                  <option key={dept.nom_departement} value={dept.nom_departement}>{dept.nom_departement}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="petrolierFilter">Pétrolier</label>
              <select
                id="petrolierFilter"
                value={selectedPetrolierFilter}
                onChange={(e) => setSelectedPetrolierFilter(e.target.value)}
              >
                <option value="">Tous les pétroliers</option>
                {petroliers.map((petrolier) => (
                  <option key={petrolier.id_petrolier} value={petrolier.id_petrolier}>{petrolier.nom_petrolier}</option>
                ))}
              </select>
            </div>
            <div className="button-group">
              <button className="rounded-button btn-primary" onClick={applyFilters}>
                Filtrer
              </button>
            </div>
          </div>
          <Pagination className="justify-content-center">
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
          <div className="text-center mb-3">
            <strong>Il y a {filteredStations.length} stations</strong>
            {canAddStation && (
              <button
                className="bouton-nouveau"
                onClick={handleAddStation}
              >
                Nouvelle station
              </button>
            )}
          </div>
          <div className="stations-container">
            {currentStations.map((station) => (
              <div key={station.id_station} className="station-card">
                <img className="station-img" src={`${process.env.PUBLIC_URL}/images/userprofil.png`} alt="Logo station" />
                <h3>{station.nom_station}</h3>
                <p><strong>Gérant:</strong> {station.nom_gerant}</p>
                <p><strong>Adresse:</strong> {station.adresse}</p>
                <p><strong>Département:</strong> {station.nom_departement}</p>
                <p><strong>Téléphone:</strong> {station.telephone}</p>
                <div className="button-group">
                  {(user.code_groupe === 'SADMIN' || (user.code_groupe === 'PETROLIER' && user.id_sgroupe === station.id_petrolier)) && (
                    <button className="rounded-button btn-primary" onClick={() => handleEditStation(station)}>Détails</button>
                  )}
                  {(user.code_groupe === 'ADMIN' || (user.code_groupe === 'PETROLIER' && user.id_sgroupe === station.id_petrolier)) && (
                    <button className="rounded-button btn-secondary" onClick={() => handleShowStationDetails(station)}>Activités</button>
                  )}
                  {user.code_groupe === 'PETROLIER' && (
                    <button 
                      className="rounded-button btn-danger" 
                      onClick={() => handleDeleteStation(station)}
                    >
                      Supprimer
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <DetailComposant 
          station={selectedStation} 
          onRetour={() => setShowDetails(false)} 
          user={user} 
        />
      )}
      
      <Toast 
        onClose={() => setShowToast(false)} 
        show={showToast} 
        delay={3000} 
        autohide 
        style={{ 
          position: 'fixed', 
          top: 20, 
          right: 20, 
          zIndex: 9999 
        }}
        bg={toastVariant}
      >
        <Toast.Header>
          <strong className="me-auto">Notification</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <AddEditStation 
        show={showAddEditModal} 
        onHide={() => setShowAddEditModal(false)} 
        station={selectedStation} 
        onSave={handleSave}
        user={user}
        onShowToast={handleShowToast}
      />
      <Modal show={showStationsModal} onHide={() => setShowStationsModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Détails de la station</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Localité</th>
                <th>Nom de la station</th>
                <th>Total des tickets vendus</th>
              </tr>
            </thead>
            <tbody>
              {stationsDetails.map((station, index) => (
                <tr key={index}>
                  <td>{station.nom_station}</td>
                  <td>{station.nom_station}</td>
                  <td>{station.tot_ticket_vendu}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <button className="rounded-button btn-primary" onClick={() => setShowStationsModal(false)}>Fermer</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListStations;
