import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Toast, Spinner, Pagination } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import PopupSubvention from './PopupSubvention';
import PopupPecheur from './PopupPecheur';
import AddEditPirogue from './AddEditPirogue'; // Import du nouveau composant
import '../styles/Pirogues.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump, faPhone, faShip, faEdit, faTrashAlt, faPlus,faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const AdminPirogue = () => {
  const { user } = useContext(SycadContext);
  const [pirogues, setPirogues] = useState([]);
  const [filteredPirogues, setFilteredPirogues] = useState([]);
  const [regions, setRegions] = useState([]);
  const [allPostes, setAllPostes] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('');
  const [matriculeFilter, setMatriculeFilter] = useState('');
  const [telephoneFilter, setTelephoneFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [showSubventionPopup, setShowSubventionPopup] = useState(false);
  const [showPecheurPopup, setShowPecheurPopup] = useState(false);
  
  // Nouveaux états pour AddEditPirogue
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedPirogue, setSelectedPirogue] = useState(null); // null pour l'ajout, objet pour l'édition

  const piroguesPerPage = 20;

  const fetchPirogues = useCallback(async () => {
    setShowToast(true);
    let query = `SELECT * FROM liste_pirogue`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setPirogues(response.datas);
      setFilteredPirogues(response.datas);
    } catch (error) {
      console.error('Error fetching pirogues', error);
    } finally {
      setShowToast(false);
    }
  }, []);

  const fetchRegions = useCallback(async () => {
    let query = `SELECT DISTINCT a_region FROM liste_pirogue`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setRegions(response.datas);
    } catch (error) {
      console.error('Error fetching regions', error);
    }
  }, []);

  const fetchPostes = useCallback(async () => {
    let query = `SELECT DISTINCT code_poste, nom_poste, a_region FROM liste_pirogue`;
    try {
      const response = await envoyerRequeteApi(query, '');
      setAllPostes(response.datas);
      setFilteredPostes(response.datas);
    } catch (error) {
      console.error('Error fetching postes', error);
    }
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      const postesForRegion = allPostes.filter(poste => poste.a_region === selectedRegion);
      setFilteredPostes(postesForRegion);
      if (selectedPoste && !postesForRegion.some(poste => poste.code_poste === selectedPoste)) {
        setSelectedPoste('');
      }
    } else {
      setFilteredPostes(allPostes);
    }
  }, [selectedRegion, allPostes, selectedPoste]);

  const applyFilters = useCallback(() => {
    let filtered = pirogues;

    if (selectedRegion) {
      filtered = filtered.filter(pirogue => pirogue.a_region === selectedRegion);
    }
    if (selectedPoste) {
      filtered = filtered.filter(pirogue => pirogue.code_poste === selectedPoste);
    }
    if (matriculeFilter) {
      filtered = filtered.filter(pirogue => pirogue.matricule.includes(matriculeFilter));
    }
    if (telephoneFilter) {
      filtered = filtered.filter(pirogue => pirogue.telephone.includes(telephoneFilter));
    }

    setFilteredPirogues(filtered);
    setCurrentPage(1);
  }, [pirogues, selectedRegion, selectedPoste, matriculeFilter, telephoneFilter]);

  useEffect(() => {
    fetchPirogues();
    fetchRegions();
    fetchPostes();
  }, [fetchPirogues, fetchRegions, fetchPostes]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handleRegionChange = (e) => {
    const newRegion = e.target.value;
    setSelectedRegion(newRegion);
    setSelectedPoste('');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (pirogue) => {
    setSelectedPirogue(pirogue);
    setShowAddEditModal(true);
  };

  const handleAddPirogue = () => {
    setSelectedPirogue(null); // Indique que c'est une opération d'ajout
    setShowAddEditModal(true);
  };

  const handleBlockPirogue = async (pirogue) => {
    const confirmBlock = window.confirm(`Êtes-vous sûr de vouloir bloquer la pirogue ${pirogue.matricule} ?`);
    
    if (confirmBlock) {
      try {
        const query = `UPDATE pirogues SET bloquer=true WHERE id_pirogue=${pirogue.id_pirogue}`;
        await envoyerRequeteApi(query, '');
        fetchPirogues();
      } catch (error) {
        console.error('Error blocking pirogue', error);
      }
    }
  };

  const indexOfLastPirogue = currentPage * piroguesPerPage;
  const indexOfFirstPirogue = indexOfLastPirogue - piroguesPerPage;
  const currentPirogues = filteredPirogues.slice(indexOfFirstPirogue, indexOfLastPirogue);

  const totalPages = Math.ceil(filteredPirogues.length / piroguesPerPage);

  return (
    <>
      <Header user={user} />
      <div className="container-list-pirogues">
        <h2>Gestion des pirogues</h2>
        <div className="form-container">
          {/* Filtres pour les pirogues */}
          <div className="form-group">
            <label htmlFor="regionSelect">Région</label>
            <select
              id="regionSelect"
              value={selectedRegion}
              onChange={handleRegionChange}
            >
              <option value="">Toutes les régions</option>
              {regions.map((region, index) => (
                <option key={index} value={region.a_region}>
                  {region.a_region}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="posteSelect">Poste</label>
            <select
              id="posteSelect"
              value={selectedPoste}
              onChange={(e) => setSelectedPoste(e.target.value)}
            >
              <option value="">Tous les postes</option>
              {filteredPostes.map((poste, index) => (
                <option key={index} value={poste.code_poste}>
                  {poste.nom_poste}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="matriculeFilter">Matricule</label>
            <input
              type="text"
              id="matriculeFilter"
              value={matriculeFilter}
              onChange={(e) => setMatriculeFilter(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="telephoneFilter">Téléphone</label>
            <input
              type="text"
              id="telephoneFilter"
              value={telephoneFilter}
              onChange={(e) => setTelephoneFilter(e.target.value)}
            />
          </div>
          <div className="button-group">
            <button className="rounded-button btn-primary" onClick={applyFilters}>
              Filtrer
            </button>
            <button className="rounded-button btn-success" onClick={handleAddPirogue}>
              <FontAwesomeIcon icon={faPlus} /> Ajouter une pirogue
            </button>
          </div>
        </div>
        <Pagination className="justify-content-center">
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
          <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
        <div className="text-center mb-3">
          <strong>Il y a {filteredPirogues.length} pirogues</strong>
        </div>
        <div className="pirogues-container">
          {currentPirogues.map((pirogue) => (
            <div key={pirogue.matricule} className="pirogue-card">
              <img className="pirogue-img" src={`${process.env.PUBLIC_URL}/images/pirogue-img.png`} alt="pirogue ico" />
              <h3>{pirogue.matricule}</h3>
              
              <p><FontAwesomeIcon icon={faShip} /> Nom Pirogue: {pirogue.nom_pirogue}</p>
              <p><FontAwesomeIcon icon={faGasPump} /> Tickets (1=10litres): {pirogue.quota}</p>
              <p><FontAwesomeIcon icon={faPhone} /> Téléphone: {pirogue.telephone}</p>
              <p>
                <FontAwesomeIcon icon={pirogue.est_change ? faBan : faCheckCircle} color={pirogue.est_change ? 'green' : 'red'} /> 
                <strong> Code changé :</strong> {pirogue.est_change ? 'Oui' : 'Non'}
              </p>
              <div className="button-group">
                <button className="rounded-button btn-warning" onClick={() => handleEdit(pirogue)}>
                  <FontAwesomeIcon icon={faEdit} /> Éditer
                </button>
                <button 
                  className="rounded-button btn-info" 
                  onClick={() => {
                    setSelectedPirogue(pirogue);
                    setShowSubventionPopup(true);
                  }}
                >
                  Subventions
                </button>
                <button 
                  className="rounded-button btn-info" 
                  onClick={() => {
                    setSelectedPirogue(pirogue);
                    setShowPecheurPopup(true);
                  }}
                >
                  Pêcheurs
                </button>
               
                <button className="rounded-button btn-danger" onClick={() => handleBlockPirogue(pirogue)}>
                  <FontAwesomeIcon icon={faTrashAlt} /> Bloquer
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Composant AddEditPirogue pour l'ajout et l'édition */}
      <AddEditPirogue
        show={showAddEditModal}
        onHide={() => setShowAddEditModal(false)}
        pirogue={selectedPirogue}
        onSave={fetchPirogues} // Rafraîchit la liste après sauvegarde
      />
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Chargement</strong>
        </Toast.Header>
        <Toast.Body>
          <Spinner animation="border" size="sm" /> Veuillez patienter pendant le chargement des pirogues.
        </Toast.Body>
      </Toast>
      <PopupSubvention 
        show={showSubventionPopup} 
        onHide={() => setShowSubventionPopup(false)} 
        pirogue={selectedPirogue} 
      />
       <PopupPecheur 
        show={showPecheurPopup} 
        onHide={() => setShowPecheurPopup(false)} 
        pirogue={selectedPirogue} 
      />
    </>
  );
};

export default AdminPirogue;
