import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import Header from '../Header';
import { SycadContext } from '../../contexts/SycadContext';
import '../styles/MonCompte.css';
const MonCompte = ({ show, onHide, onSave }) => {
  const { user } = useContext(SycadContext); // Récupérer l'utilisateur connecté
  const [formData, setFormData] = useState({
    login_agent: '',
    hash_password_agent: '',
    nom_agent: '',
    prenom_agent: '',
    tel_agent: '',
    user_picture: '', // Ajout du champ image
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  // Initialisation des données de l'utilisateur connecté
  useEffect(() => {
    if (user) {
      console.log('Utilisateur chargé:', user);
      setFormData({
        login_agent: user.login_agent || '',
        hash_password_agent: '',
        nom_agent: user.nom_agent || '',
        prenom_agent: user.prenom_agent || '',
        tel_agent: user.telephone || '',
        user_picture: user.user_picture || '', // Initialisation de l'image
      });
    }
  }, [user]);

  // Gestion des changements dans les champs du formulaire
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'user_picture') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0], // Gestion du téléchargement de fichier
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Soumission du formulaire pour modifier les détails de l'utilisateur
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.confirm('Voulez-vous vraiment continuer ?')) {
      setLoading(true);
      try {
        const query = `
          SELECT maj_compte_agent(
            '${formData.nom_agent.toUpperCase()}',
            '${formData.prenom_agent}',
            '${formData.tel_agent}',
            ${user.id_agent}
          ) AS result
        `;

        console.log('Requête pour modifier les détails de l\'utilisateur:', query);
        const response = await envoyerRequeteApi(query, '');
        const result = response.datas[0].result;

        if (result.code === "200") {
          alert('Le compte est modifié avec succès.');
          onSave();
          onHide();
          return;
        } else {
          setToastMessage(result.donnee);
        }
        setShowToast(true);
      } catch (error) {
        console.error('Erreur lors de la modification des détails de l\'utilisateur:', error);
        setToastMessage('Erreur lors de la modification des détails de l\'utilisateur.');
        setShowToast(true);
      } finally {
        setLoading(false);
      }
    }
  };

  // Soumission du formulaire pour changer le mot de passe
  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setToastMessage('Les nouveaux mots de passe ne correspondent pas.');
      setShowToast(true);
      return;
    }

    setLoading(true);
    try {
      const query = `
        SELECT maj_password(${user.id_agent},'${oldPassword}','${newPassword}');
      `;
      console.log('Requête pour changer le mot de passe:', query);
      const result = await envoyerRequeteApi(query, '');
      const changePasswordResult = result.datas[0]?.maj_password;

      if (changePasswordResult === 'OK') {
        setToastMessage('Mot de passe modifié avec succès.');
        setShowPasswordModal(false);
      } else {
        setToastMessage(changePasswordResult || 'Erreur lors de la modification du mot de passe.');
      }
      setShowToast(true);
    } catch (error) {
      console.error('Erreur lors de la modification du mot de passe:', error);
      setToastMessage('Erreur lors de la modification du mot de passe.');
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="container">
        <Header />
        <h2>Chargement...</h2>
      </div>
    );
  }

  return (
    <>
      <Header user={user} />
      <div className="container-moncompte">
        <div className="cardpsearch">
          <div className="cardheader">Modifier les détails du compte</div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="text-center mb-4-moncompte">
                <img
                  src={formData.user_picture ? URL.createObjectURL(formData.user_picture) : `${process.env.PUBLIC_URL}/images/usertete.png`}
                  alt="User"
                  className="rounded-circle-img"
                 
                />
              </div>

              <div className="form-group">
                <label htmlFor="login_agent">Login</label>
                <input
                  type="text"
                  className="form-control"
                  id="login_agent"
                  name="login_agent"
                  value={formData.login_agent}
                  onChange={handleChange}
                  disabled
                />
              </div>

              <div className="form-group">
                <label htmlFor="hash_password_agent">Mot de passe</label>
                <input
                  type="password"
                  className="form-control"
                  id="hash_password_agent"
                  name="hash_password_agent"
                  value="*********************"
                  onChange={handleChange}
                  disabled
                />
                <button type="button" className="btn-link-moncompte" onClick={() => setShowPasswordModal(true)}>
                  Changer mot de passe
                </button>
              </div>

              <div className="form-group">
                <label htmlFor="nom_agent">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  id="nom_agent"
                  name="nom_agent"
                  value={formData.nom_agent}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="prenom_agent">Prénom</label>
                <input
                  type="text"
                  className="form-control"
                  id="prenom_agent"
                  name="prenom_agent"
                  value={formData.prenom_agent}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="tel_agent">Téléphone</label>
                <input
                  type="text"
                  className="form-control"
                  id="tel_agent"
                  name="tel_agent"
                  value={formData.tel_agent}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="button-group-moncompte">
                <button type="submit" className="btn-save-moncompte" disabled={loading}>
                  {loading ? 'Enregistrement...' : 'Enregistrer'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Changer mot de passe</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="form-group">
      <label htmlFor="oldPassword">Ancien mot de passe</label>
      <input
        type="password"
        className="form-control"
        id="oldPassword"
        name="oldPassword"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        required
      />
    </div>
    <div className="form-group">
      <label htmlFor="newPassword">Nouveau mot de passe</label>
      <input
        type="password"
        className="form-control"
        id="newPassword"
        name="newPassword"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />
    </div>
    <div className="form-group">
      <label htmlFor="confirmPassword">Confirmer le nouveau mot de passe</label>
      <input
        type="password"
        className="form-control"
        id="confirmPassword"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>
      Annuler
    </Button>
    <Button variant="primary" onClick={handleChangePassword} disabled={loading}>
      {loading ? 'Validation...' : 'Valider'}
    </Button>
  </Modal.Footer>
</Modal>

      {showToast && (
        <div className="toast toast-show">
          <div className="toast-header">
            <strong className="mr-auto">Notification</strong>
            <button type="button" className="close" onClick={() => setShowToast(false)}>
              &times;
            </button>
          </div>
          <div className="toast-body">
            {toastMessage}
          </div>
        </div>
      )}
    </>
  );
};

export default MonCompte;
