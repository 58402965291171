import React, { useState, useEffect,useContext } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
const PopupStation = ({ show, onHide, petrolierId, onStationClick }) => {
  const [stations, setStations] = useState([]);
  const { user } = useContext(SycadContext);
  const isUserAuthorized = user &&  user.code_groupe === "DOUANE";
  useEffect(() => {
    if (petrolierId) {
      // Vider le tableau avant de charger les nouvelles stations
      setStations([]);

      const fetchStations = async () => {
        const query = `SELECT * FROM liste_station WHERE id_petrolier=${petrolierId}`;
        try {
          const response = await envoyerRequeteApi(query, '');
          setStations(response.datas);
        } catch (error) {
          console.error('Error fetching stations', error);
        }
      };

      fetchStations();
    }
  }, [petrolierId]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Liste des stations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nom de la station</th>
              <th>Adresse</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {stations.map((station) => (
              <tr key={station.id_station}>
                <td>{station.nom_station}</td>
                <td>{station.adresse}</td>
                <td>
                  <Button 
                   variant="primary" 
                   disabled={!isUserAuthorized}
                   onClick={() => onStationClick(station)}>
                    Voir Détails

                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupStation;
