import React, { createContext, useState, useCallback } from 'react';

// Création du contexte
export const SycadContext = createContext();

// Fournisseur de contexte
export const SycadProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [appParams, setAppParams] = useState({});
  const [connectedStation, setConnectedStation] = useState(null);
  const [connectedPoste, setConnectedPoste] = useState(null);
  const [connectedPetrolier, setConnectedPetrolier] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // Fonction de déconnexion avec log
  const logout = useCallback(() => {
    console.log("Logout function called. Current user:", user);
    setUser(null);
    setAppParams({});
    setConnectedStation(null);
    setConnectedPoste(null);
    setConnectedPetrolier(null);
    setIsAdmin(false);
    console.log("Logout complete. User set to null.");
  }, [user]);

  // Fonction de mise à jour de l'utilisateur avec log
  const setUserWithLog = useCallback((newUser) => {
    console.log("Setting new user:", newUser);
    setUser(newUser);
  }, []);

  return (
    <SycadContext.Provider value={{
      user,
      setUser: setUserWithLog,
      appParams,
      setAppParams,
      connectedStation,
      setConnectedStation,
      connectedPoste,
      setConnectedPoste,
      connectedPetrolier,
      setConnectedPetrolier,
      isAdmin,
      setIsAdmin,
      logout
    }}>
      {children}
    </SycadContext.Provider>
  );
};
