import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';

const PopupBlocage = ({ show, onHide, pirogue, onSave }) => {
  const [motif, setMotif] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const { user } = useContext(SycadContext);
  const [typesBlocage, setTypesBlocage] = useState([]);

  useEffect(() => {
    if (pirogue) {
      setMotif('');
      setSelectedType('');
      fetchTypesBlocage();
    }
    console.log('Edit pirogue', pirogue);
  }, [pirogue]);

  const fetchTypesBlocage = async () => {
    try {
      const response = await envoyerRequeteApi('SELECT id_type, nom_infraction FROM type_blocage ORDER BY nom_infraction', '');
      setTypesBlocage(response.datas);
    } catch (error) {
      console.error('Error fetching types de blocage', error);
    }
  };

  const handleSubmit = async () => {
    try {
      let query = '';
      if (pirogue.bloquer) {
        // Débloquer la pirogue
        query = `INSERT INTO historique_blocage_pirogues (id_pirogue, bloquer, motif,id_agent) 
        VALUES (${pirogue.id_pirogue}, false, 'Pirogue debloquee par ${user.login_agent}', ${user.id_agent}) returning id_historique`;
      } else {
        // Bloquer la pirogue
        query = `INSERT INTO historique_blocage_pirogues (id_pirogue, bloquer, id_type, motif,id_agent) 
        VALUES (${pirogue.id_pirogue}, true, ${selectedType}, '${motif} par ${user.login_agent}', ${user.id_agent}) returning id_historique`;
      }
      console.log('save blocage: ',query);
      const result = await envoyerRequeteApi(query, '');
      console.log('result blocage: ',result);
      onSave();  // Rafraîchit la liste des pirogues
      onHide();  // Ferme le popup
    } catch (error) {
      console.error('Error blocking/unblocking pirogue', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{pirogue?.bloquer ? 'Débloquer la pirogue' : 'Bloquer la pirogue'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {!pirogue?.bloquer && (
            <Form.Group controlId="formTypeBlocage">
              <Form.Label>Type d'infraction</Form.Label>
              <Form.Control
                as="select"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                required
              >
                <option value="">Sélectionnez un type</option>
                {typesBlocage.map((type) => (
                  <option key={type.id_type} value={type.id_type}>
                    {type.nom_infraction}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group controlId="formMotif">
            <Form.Label>Motif</Form.Label>
            <Form.Control
              type="text"
              value={motif}
              onChange={(e) => setMotif(e.target.value)}
              placeholder="Entrez le motif"
              maxLength={255}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {pirogue?.bloquer ? 'Débloquer' : 'Bloquer'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupBlocage;
