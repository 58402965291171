import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';

const EditPecheurModal = ({ show, onHide, pecheur, onSave }) => {
  const [formData, setFormData] = useState({
    nom_pecheur: '',
    num_id: '',
    tel_pecheur: '',
    actif: true,
  });

  useEffect(() => {
    if (pecheur) {
      setFormData({
        nom_pecheur: pecheur.nom_pecheur || '',
        num_id: pecheur.num_id || '',
        tel_pecheur: pecheur.tel_pecheur || '',
        actif: pecheur.actif || true,
      });
    }
  }, [pecheur]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const query = `
        UPDATE pecheur
        SET 
          nom_pecheur = '${formData.nom_pecheur}',
          num_id = '${formData.num_id}',
          tel_pecheur = '${formData.tel_pecheur}',
          actif = ${formData.actif}
        WHERE id_pecheur = ${pecheur.id_pecheur}
        returning id_pecheur 
      `;
      console.log('query save pecheur', query);
      const result = await envoyerRequeteApi(query, '');
      console.log('result save pecheur:', result);
      onSave();
      onHide();
    } catch (error) {
      console.error('Error updating pecheur', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Éditer Pêcheur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formNomPecheur">
            <Form.Label>Nom du Pêcheur</Form.Label>
            <Form.Control
              type="text"
              name="nom_pecheur"
              value={formData.nom_pecheur}
              onChange={handleInputChange}
              placeholder="Entrez le nom du pêcheur"
              required
            />
          </Form.Group>
          <Form.Group controlId="formNumId">
            <Form.Label>Numéro ID</Form.Label>
            <Form.Control
              type="text"
              name="num_id"
              value={formData.num_id}
              onChange={handleInputChange}
              placeholder="Entrez le numéro ID"
              required
            />
          </Form.Group>
          <Form.Group controlId="formTelPecheur">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="tel_pecheur"
              value={formData.tel_pecheur}
              onChange={handleInputChange}
              placeholder="Entrez le numéro de téléphone"
              required
            />
          </Form.Group>
          <Form.Group controlId="formActif">
            <Form.Check 
              type="checkbox"
              name="actif"
              label="Actif"
              checked={formData.actif}
              onChange={(e) => setFormData({ ...formData, actif: e.target.checked })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Sauvegarder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPecheurModal;
