import { useEffect, useRef, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const SessionTimeout = ({ timeout = 3600000 }) => { // timeout in milliseconds (3 minutes=180000)
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  const events = useMemo(() => ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'], []);

  const logout = useCallback(() => {
    // Clear the session data and navigate to login
    sessionStorage.clear();
    navigate('/');
  }, [navigate]);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(logout, timeout);
  }, [logout, timeout]);

  useEffect(() => {
    events.forEach(event => window.addEventListener(event, resetTimeout));
    resetTimeout();

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimeout));
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [events, resetTimeout]);

  return null;
};

export default SessionTimeout;
