import React, { useState, useContext } from 'react';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import { envoyerRequeteApi } from '../apis/api';
import envoyerSMS from '../apis/send_message'; 
import '../styles/MajPecheurEtPin.css'; // Importer le fichier de style personnalisé

const MajPecheurEtPin = () => {
    const { user } = useContext(SycadContext);
    const [matricule, setMatricule] = useState('');
    const [pirogueTrouvee, setPirogueTrouvee] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newCodePin, setNewCodePin] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const handleMatriculeChange = async (e) => {
        const value = e.target.value;
        setMatricule(value);

        if (value.length < 10) {
            setPirogueTrouvee(null);
            return;
        }

        if (value.length === 10) {
            setLoading(true);
            try {
                const query = `
                SELECT p.id_pirogue, lp.telephone as tel_proprietaire, lp.nom_pirogue, p.tel_pecheur, p.nom_pecheur, p.num_id, lp.est_change as code_change
                FROM liste_pirogue lp 
                JOIN pecheur p ON p.id_pirogue = lp.id_pirogue 
                WHERE lp.matricule = '${value}'
                `;
                const response = await envoyerRequeteApi(query, '');
                const data = response.datas[0];
                if (data) {
                    setPirogueTrouvee(data);
                } else {
                    alert('Pirogue non trouvée');
                    setShowToast(true);
                    setPirogueTrouvee(null);
                }
            } catch (error) {
                console.error('Erreur lors de la recherche de la pirogue:', error);
                alert('Erreur lors de la recherche de la pirogue');
                setShowToast(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPirogueTrouvee({ ...pirogueTrouvee, [name]: value });
    };

    const handleSave = async () => {
        if (!pirogueTrouvee) return;

        if (!newCodePin || newCodePin.length < 4) {
            alert('Veuillez entrer un code PIN');
            setShowToast(true);
            return;
        }

        if (!pirogueTrouvee.tel_pecheur || pirogueTrouvee.tel_pecheur.length < 9) {
            alert('Veuillez entrer un numéro de téléphone valide');
            setShowToast(true);
            return;
        }

        if (!pirogueTrouvee.nom_pecheur || pirogueTrouvee.nom_pecheur.length < 3) {
            alert('Veuillez entrer un nom de pêcheur valide');
            setShowToast(true);
            return;
        }

        setLoading(true);
        try {
            const query = `
            SELECT maj_pecheuretpin(
                ${pirogueTrouvee.id_pirogue},
                '${pirogueTrouvee.nom_pecheur}',
                '${pirogueTrouvee.tel_pecheur}',
                '${pirogueTrouvee.num_id}',
                '${newCodePin}'
            ) AS result
            `;
            console.log("Query: maj_pecheuretpin : ", query);
            const response = await envoyerRequeteApi(query, '');
            console.log("Response: maj_pecheuretpin : ", response);
            const result = response.datas[0].result;

            if (result.code === "200") {
                setToastMessage('Modification réussie pour le pêcheur et pour le code PIN');
                setPirogueTrouvee(null);
                setMatricule('');
                setNewCodePin('');
                const message = `Salam, Vous venez de modifier votre code secret avec succès. Votre nouveau code PIN est ${newCodePin}.`;
                const telephone = pirogueTrouvee.tel_pecheur;
                await envoyerSMS(telephone, message);
            } else {
                setToastMessage(result.donnee);
            }
            setShowToast(true);
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement:', error);
            setToastMessage('Erreur lors de l\'enregistrement');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setPirogueTrouvee(null);
        setMatricule('');
        setNewCodePin('');
    };

    return (
        <> 
          <Header user={user} />
          <h2>CHANGEMENT DE CODE PIN DU PECHEUR</h2>
          <div className="container">
            <div className="cardpsearch">
              <div className="cardheader">
                Rechercher la pirogue et et mettre à jour les informations du pêcheur et du code PIN
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="matricule">Matricule</label>
                  <input
                    type="text"
                    id="matricule"
                    name="matricule"
                    value={matricule}
                    onChange={handleMatriculeChange}
                    placeholder="Entrez le matricule"
                    maxLength={10}
                    required
                    className="form-control"
                  />
                </div>
    
                {pirogueTrouvee && (
                  <>
                    <div className="form-group">
                      <label htmlFor="nom_pirogue">Nom de la pirogue</label>
                      <input
                        type="text"
                        id="nom_pirogue"
                        name="nom_pirogue"
                        value={pirogueTrouvee.nom_pirogue || 'NON DEFINI'}
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="tel_proprietaire">Téléphone du propriétaire</label>
                      <input
                        type="text"
                        id="tel_proprietaire"
                        name="tel_proprietaire"
                        value={pirogueTrouvee.tel_proprietaire || 'NON DEFINI'}
                        readOnly
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="nom_pecheur">Nom du pêcheur</label>
                      <input
                        type="text"
                        id="nom_pecheur"
                        name="nom_pecheur"
                        value={pirogueTrouvee.nom_pecheur || 'NON DEFINI'}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="tel_pecheur">Téléphone du pêcheur</label>
                      <input
                        type="text"
                        id="tel_pecheur"
                        name="tel_pecheur"
                        value={pirogueTrouvee.tel_pecheur || 'NON DEFINI'}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="num_id">Numéro d'identification</label>
                      <input
                        type="text"
                        id="num_id"
                        name="num_id"
                        value={pirogueTrouvee.num_id || 'NON DEFINI'}
                        onChange={(e) => setPirogueTrouvee({ ...pirogueTrouvee, num_id: e.target.value })}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="newCodePin">Nouveau code PIN</label>
                      <input
                        type="text"
                        id="newCodePin"
                        name="newCodePin"
                        value={newCodePin}
                        onChange={(e) => setNewCodePin(e.target.value)}
                        placeholder="Entrez le nouveau code PIN"
                        maxLength={4}
                        className="form-control"
                      />
                    </div>
    
                    <div className="button-group">
                      <button 
                        className="btn-save"
                        onClick={handleSave}
                        disabled={loading}
                      >
                        Enregistrer
                      </button>
                      <button 
                        className="btn-cancel"
                        onClick={handleCancel}
                      >
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
    
            {showToast && (
              <div className="toast toast-show">
                <div className="toast-header">
                  <strong className="me-auto">Notification</strong>
                  <button type="button" onClick={() => setShowToast(false)}>X</button>
                </div>
                <div className="toast-body">
                  {toastMessage}
                </div>
              </div>
            )}
          </div>
        </>
      );
    };
    
    export default MajPecheurEtPin;