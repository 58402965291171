import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';

const AddPetrolier = ({ show, onHide, petrolier, onSave }) => {
  const [nom, setNom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [actif, setActif] = useState(true);

  useEffect(() => {
    if (show) {
      if (petrolier) {
        setNom(petrolier.nom_petrolier);
        setAdresse(petrolier.adresse);
        setEmail(petrolier.email);
        setTelephone(petrolier.telephone);
        setActif(petrolier.actif);
      } else {
        // Vider les champs si aucun petrolier n'est passé
        setNom('');
        setAdresse('Dakar'); // Adresse par défaut
        setEmail('');
        setTelephone('');
        setActif(true);
      }
    }
  }, [petrolier, show]);

  const handleNomChange = (e) => {
    const newNom = e.target.value;
    setNom(newNom);
    if (!petrolier) { // Remplir automatiquement l'email seulement lors de l'ajout
      setEmail(`admin@${newNom}`);
    }
  };

  const handleActifChange = async () => {
    const action = actif ? 'désactiver' : 'activer';
    const confirmed = window.confirm(`Êtes-vous sûr de vouloir ${action} le compte de ${nom} ?`);
    if (!confirmed) return;

    try {
      // Appeler la fonction PostgreSQL pour activer/désactiver le pétrolier
      const query = `SELECT * from activer_desactiver_petrolier(${petrolier.id_petrolier})`;
      console.log("Activer/Désactiver petrolier:", query);
      const response = await envoyerRequeteApi(query, '');
      console.log("Reponse:", response);
      // Mettre à jour l'état local en fonction de la réponse
      if (response && response.datas && response.datas.length > 0) {
        alert(response.datas[0].activer_desactiver_petrolier);
        setActif(!actif); // Inverser l'état local pour refléter le changement
      }
    } catch (error) {
      console.error('Error updating petrolier status', error);
      alert('Une erreur s\'est produite lors de la mise à jour du statut du pétrolier.');
    }
  };

  const handleSubmit = async () => {
    // Demander confirmation avant de soumettre
    const confirmed = window.confirm("Êtes-vous sûr de vouloir enregistrer ces informations ?");
    if (!confirmed) return;

    const query = petrolier
      ? `UPDATE petrolier SET nom_petrolier='${nom}', adresse='${adresse}', email='${email}', telephone='${telephone}', actif=${actif} WHERE id_petrolier=${petrolier.id_petrolier}`
      : `INSERT INTO petrolier (nom_petrolier, adresse, email, telephone, actif) VALUES ('${nom}', '${adresse}', '${email}', '${telephone}', ${actif})`;

    try {
      console.log('add petrolier query', query);
      await envoyerRequeteApi(query, '');
      onSave();
      onHide();
    } catch (error) {
      console.error('Error saving petrolier', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{petrolier ? 'Modifier' : 'Ajouter'} un pétrolier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Nom du pétrolier</Form.Label>
            <Form.Control
              type="text"
              value={nom}
              onChange={handleNomChange}
              disabled={!!petrolier} // Grise le champ en mode modification
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Adresse</Form.Label>
            <Form.Control
              type="text"
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check 
              type="checkbox"
              checked={actif}
              onChange={handleActifChange} // Appeler la fonction pour activer/désactiver
              label={actif ? "Désactiver" : "Activer"}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPetrolier;
