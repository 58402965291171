import React, { useContext } from 'react';
import MainContent from './MainContent';
import Header from './Header';
import { SycadContext } from '../contexts/SycadContext';

const Layout = ({ children }) => {
  const { user } = useContext(SycadContext);

  return (
    <div>
      <Header  user={user} />
      <MainContent>
        {children}
      </MainContent>
    </div>
  );
};

export default Layout;
