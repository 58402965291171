import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import '../styles/AddEditPirogue.css';

const AddEditPirogue = ({ show, onHide, pirogue, onSave }) => {
  const [formData, setFormData] = useState({
    matricule: '',
    nom_pirogue: '',
    telephone: '',
    a_region: '',
    a_port: '',
    code_poste: '',
    id_poste_origine: '',
    id_gere: '',
  });

  const [regions, setRegions] = useState([]);
  const [ports, setPorts] = useState([]);
  const [postes, setPostes] = useState([]);
  const [geres, setGeres] = useState([]);
  const [filteredPorts, setFilteredPorts] = useState([]);
  const [filteredPostes, setFilteredPostes] = useState([]);

  useEffect(() => {
    if (pirogue) {
      setFormData({
        matricule: pirogue.matricule || '',
        nom_pirogue: pirogue.nom_pirogue || '',
        telephone: pirogue.telephone || '',
        a_region: pirogue.a_region || '',
        a_port: pirogue.a_port || '',
        code_poste: pirogue.code_poste || '',
        id_poste_origine: pirogue.id_poste_origine || '',
        id_gere: pirogue.id_gere || '',
      }); 
      console.log('Edit pirogue', pirogue);
    } else {
      setFormData({
        matricule: '',
        nom_pirogue: '',
        telephone: '',
        a_region: '',
        a_port: '',
        code_poste: '',
        id_poste_origine: '',
        id_gere: '',
      });
    }
    // Charger les données des tables
    fetchForeignKeyData();
  }, [pirogue]);

  const fetchForeignKeyData = async () => {
    try {
      const regionsRes = await envoyerRequeteApi('SELECT DISTINCT btrim(code_region) as code_region, nom_region FROM liste_poste ORDER BY nom_region', '');
      const portsRes = await envoyerRequeteApi('SELECT DISTINCT id_port, btrim(a_port) as a_port, nom_port, btrim(code_region) as code_region FROM liste_poste ORDER BY nom_port', '');
      const postesRes = await envoyerRequeteApi('SELECT DISTINCT id_poste, btrim(code_poste) as code_poste, nom_poste, btrim(code_region) as code_region FROM liste_poste ORDER BY nom_poste', '');
      const geresRes = await envoyerRequeteApi('SELECT id_gere, nom_profil FROM gere_card', '');

      setRegions(regionsRes.datas);
      setPorts(portsRes.datas);
      setPostes(postesRes.datas);
      setGeres(geresRes.datas);
    } catch (error) {
      console.error('Error fetching foreign key data', error);
    }
  };

  useEffect(() => {
    // Filtrer les ports et les postes en fonction de la région sélectionnée
    const filteredPorts = ports.filter(port => port.code_region === formData.a_region);
    const filteredPostes = postes.filter(poste => poste.code_region === formData.a_region);

    setFilteredPorts(filteredPorts);
    setFilteredPostes(filteredPostes);
  }, [formData.a_region, ports, postes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const { matricule, nom_pirogue, telephone, a_region, a_port, code_poste, id_poste_origine, id_gere } = formData;
    console.log('Form data:', formData);
    try {
      let query = '';
      if (pirogue) {
        // Édition d'une pirogue existante
        query = `SELECT add_new_pirogue('${matricule}', '${nom_pirogue}', '${telephone}', '${a_region}', '${a_port}', '${code_poste}', ${id_poste_origine}, ${id_gere})`;
      } else {
        // Ajout d'une nouvelle pirogue
        query = `SELECT add_new_pirogue('${matricule}', '${nom_pirogue}', '${telephone}', '${a_region}', '${a_port}', '${code_poste}', ${id_poste_origine}, ${id_gere})`;
      }

      console.log('Query save:', query);
      const result = await envoyerRequeteApi(query, '');

      console.log('Result:', result);
      if (result.datas[0]) {
        const response = result.datas[0].add_new_pirogue;
        if (response.startsWith('OK#')) {
          alert('Pirogue ajoutée avec succès.');
        } else if (response.startsWith('OKEXIST#')) {
          alert('La pirogue existe déjà. Informations mises à jour.');
        }
        onSave();  // Rafraîchit la liste des pirogues
        onHide();  // Ferme le modal
      } else {
        alert('Une erreur est survenue lors de la sauvegarde. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Error saving pirogue', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{pirogue ? 'Éditer la pirogue' : 'Ajouter une pirogue'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formMatricule">
            <Form.Label>Matricule</Form.Label>
            <Form.Control
              type="text"
              name="matricule"
              value={formData.matricule}
              onChange={handleInputChange}
              placeholder="Entrez le matricule"
              required
            />
          </Form.Group>
          <Form.Group controlId="formNomPirogue">
            <Form.Label>Nom de la Pirogue</Form.Label>
            <Form.Control
              type="text"
              name="nom_pirogue"
              value={formData.nom_pirogue}
              onChange={handleInputChange}
              placeholder="Entrez le nom de la pirogue"
              required
            />
          </Form.Group>
          <Form.Group controlId="formTelephone">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control
              type="text"
              name="telephone"
              value={formData.telephone}
              onChange={handleInputChange}
              placeholder="Entrez le numéro de téléphone"
              required
            />
          </Form.Group>
          <Form.Group controlId="formRegion">
            <Form.Label>Région</Form.Label>
            <Form.Control
              as="select"
              name="a_region"
              value={formData.a_region}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionnez une région</option>
              {regions.map((region) => (
                <option key={region.code_region} value={region.code_region}>
                  {region.nom_region}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPort">
            <Form.Label>Port</Form.Label>
            <Form.Control
              as="select"
              name="a_port"
              value={formData.a_port}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionnez un port</option>
              {filteredPorts.map((port) => (
                <option key={port.a_port} value={port.a_port}>
                  {port.nom_port}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPoste">
            <Form.Label>Poste</Form.Label>
            <Form.Control
              as="select"
              name="code_poste"
              value={formData.code_poste}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionnez un poste</option>
              {filteredPostes.map((poste) => (
                <option key={poste.id_poste} value={poste.code_poste}>
                  {poste.nom_poste}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPosteOrigine">
            <Form.Label>Poste d'Origine</Form.Label>
            <Form.Control
              as="select"
              name="id_poste_origine"
              value={formData.id_poste_origine}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionnez un poste d'origine</option>
              {filteredPostes.map((poste) => (
                <option key={poste.id_poste} value={poste.id_poste}>
                  {poste.nom_poste}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formGere">
            <Form.Label>Catégorie carte pêcheur</Form.Label>
            <Form.Control
              as="select"
              name="id_gere"
              value={formData.id_gere}
              onChange={handleInputChange}
              required
            >
              <option value="">Sélectionnez une catégorie</option>
              {geres.map((gere) => (
                <option key={gere.id_gere} value={gere.id_gere}>
                  {gere.nom_profil}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {pirogue ? 'Sauvegarder les modifications' : 'Ajouter'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditPirogue;
