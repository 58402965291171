import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Toast, Spinner, Pagination } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import { SycadContext } from '../../contexts/SycadContext';
import Header from '../Header';
import AddPetrolier from './AddPetrolier';
import PopupStation from './PopupStation';
import DetailComposant from './DetailComposant'; // Assurez-vous d'importer DetailComposant
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faGasPump,faCheckCircle,faBan } from '@fortawesome/free-solid-svg-icons';
import '../styles/Petroliers.css';

const ListPetroliers = () => {
  const { user } = useContext(SycadContext);
  const [petroliers, setPetroliers] = useState([]);
  const [filteredPetroliers, setFilteredPetroliers] = useState([]);
  const [petrolierNameFilter, setPetrolierNameFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [showAddPetrolierModal, setShowAddPetrolierModal] = useState(false);
  const [showListStationsModal, setShowListStationsModal] = useState(false);
  const [selectedPetrolierId, setSelectedPetrolierId] = useState(null);
  const [selectedPetrolier, setSelectedPetrolier] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null); // Nouvel état pour la station sélectionnée
  const petroliersPerPage = 20;
  const isUserAuthorized = user && user.code_groupe === "COMMISSION";
  const fetchPetroliers = useCallback(async () => {
    setShowToast(true);
    const query = `SELECT * FROM liste_petroliers`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setPetroliers(response.datas);
      setFilteredPetroliers(response.datas);
    } catch (error) {
      console.error('Error fetching petroliers', error);
    } finally {
      setShowToast(false);
    }
  }, []);

  useEffect(() => {
    fetchPetroliers();
  }, [fetchPetroliers]);

  const applyFilters = useCallback(() => {
    let filtered = petroliers;

    if (petrolierNameFilter) {
      filtered = filtered.filter(petrolier => petrolier.nom_petrolier.toLowerCase().includes(petrolierNameFilter.toLowerCase()));
    }

    setFilteredPetroliers(filtered);
  }, [petroliers, petrolierNameFilter]);

  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddPetrolier = () => {
    setSelectedPetrolier(null);
    setShowAddPetrolierModal(true);
  };

  const handleShowStations = (petrolierId) => {
    setSelectedPetrolierId(petrolierId);
    setShowListStationsModal(true);
  };

  const handleEditPetrolier = (petrolier) => {
    setSelectedPetrolier(petrolier);
    setShowAddPetrolierModal(true);
  };
  const handleStationClick = (station) => {
    setSelectedStation(station); // Met à jour la station sélectionnée
    setShowListStationsModal(false); // Ferme le modal
  };

  const handleRetour = () => {
    setSelectedStation(null); // Réinitialise la station sélectionnée pour revenir à la liste des pétroliers
  };

  const indexOfLastPetrolier = currentPage * petroliersPerPage;
  const indexOfFirstPetrolier = indexOfLastPetrolier - petroliersPerPage;
  const currentPetroliers = filteredPetroliers.slice(indexOfFirstPetrolier, indexOfLastPetrolier);

  const totalPages = Math.ceil(filteredPetroliers.length / petroliersPerPage);

  return (
    <>
      <Header user={user} />
      {!selectedStation ? (
        <div className="container-list-petroliers">
          <h2>Liste des pétroliers</h2>
          
          <div className="form-container">
            <div className="form-group">
              <label htmlFor="petrolierNameFilter">Filtrer par nom du pétrolier</label>
              <input
                type="text"
                id="petrolierNameFilter"
                value={petrolierNameFilter}
                onChange={(e) => setPetrolierNameFilter(e.target.value)}
              />
   
            </div>
          </div>
          <Pagination className="justify-content-center">
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
          <div className="text-center mb-3">
            <strong>Il y a {filteredPetroliers.length} pétroliers</strong>
            <button
              className="bouton-nouveau"
              onClick={handleAddPetrolier}
              disabled={!isUserAuthorized}
              hidden={!isUserAuthorized}
            >
              Nouveau pétrolier
            </button>
          </div>
          <div className="petroliers-container">
            {currentPetroliers.map((petrolier) => (
              <div key={petrolier.id_petrolier} className="petrolier-card">
                <img className="petrolier-img" src={`${process.env.PUBLIC_URL}/images/userprofil.png`} alt="Logo petrolier" />
                <h3>{petrolier.nom_petrolier}</h3>
                <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Adresse:</strong> {petrolier.adresse}</p>
                <p><FontAwesomeIcon icon={faPhone} /> <strong>Téléphone:</strong> {petrolier.telephone}</p>
                <p><FontAwesomeIcon icon={faGasPump} /> <strong>Nbre stations:</strong> {petrolier.tot_stations}</p>
                <p>
                  <FontAwesomeIcon icon={petrolier.actif ? faCheckCircle : faBan} color={petrolier.actif ? 'green' : 'red'} /> 
                  <strong> Actif:</strong> {petrolier.actif ? 'Oui' : 'Non'}
                </p>
                <p>
                  <FontAwesomeIcon icon={petrolier.bloquer ? faBan : faCheckCircle} color={petrolier.bloquer ? 'red' : 'green'} /> 
                  <strong> Bloqué:</strong> {petrolier.bloquer ? 'Oui' : 'Non'}
                </p>
                <div className="button-group">
                  <button className="rounded-button btn-primary" hidden={!isUserAuthorized} onClick={() => handleEditPetrolier(petrolier)}>Afficher</button>
                  <button className="rounded-button btn-primary" onClick={() => handleShowStations(petrolier.id_petrolier)}>Stations</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <DetailComposant
          station={selectedStation}
          onRetour={handleRetour}
          user={user}
        />
      )}

      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide style={{ position: 'absolute', top: 20, right: 20 }}>
        <Toast.Header>
          <strong className="me-auto">Chargement</strong>
        </Toast.Header>
        <Toast.Body>
          <Spinner animation="border" size="sm" /> Veuillez patienter pendant le chargement des pétroliers.
        </Toast.Body>
      </Toast>

      <AddPetrolier 
        show={showAddPetrolierModal} 
        onHide={() => setShowAddPetrolierModal(false)} 
        petrolier={selectedPetrolier} 
        onSave={fetchPetroliers} 
      />

      <PopupStation 
        show={showListStationsModal} 
        onHide={() => setShowListStationsModal(false)} 
        petrolierId={selectedPetrolierId} 
        onStationClick={handleStationClick} // Passez la fonction de rappel pour sélectionner une station
      />
    </>
  );
};

export default ListPetroliers;
