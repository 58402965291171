import React, { useState, useEffect, useContext, useCallback ,useMemo } from 'react';
import { envoyerRequeteApi } from '../apis/api';
import '../styles/ListDemande.css';
import { SycadContext } from '../../contexts/SycadContext';
import AddCommande from './AddCommande';
import PopupDemande from './PopupDemande';
import Header from '../Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboardList, faQrcode } from '@fortawesome/free-solid-svg-icons';

const ListDemande = () => {
  const { user } = useContext(SycadContext);
  const [demandes, setDemandes] = useState([]);
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [etatDemande, setEtatDemande] = useState('');
  const [ticketsDispo, setTicketsDispo] = useState(0);
  const [etats, setEtats] = useState([]);
  const [selectedDemande, setSelectedDemande] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showAddCommande, setShowAddCommande] = useState(false);
  const [filteredDemandesCount, setFilteredDemandesCount] = useState(0);

  const isAdminOrPeche = user && (user.code_groupe === "ADMIN" || user.code_groupe === "PECHE");
  const isUserAuthorized = user && user.code_groupe === "PECHE";

  const formatDate = useCallback((date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    setDateDebut(formatDate(startDate));
    setDateFin(formatDate(currentDate));
  }, [formatDate]);

  const fetchDemandes = useCallback(async () => {
    if (!dateDebut || !dateFin) return;

    let query = `
    SELECT *
    FROM list_demandes
    WHERE date_demande BETWEEN '${dateDebut}' AND '${dateFin}'
    ${etatDemande ? `AND id_etat = ${etatDemande}` : ''}
    ORDER BY id_demande DESC`;
    console.log('Liste des demandes', query);
    try {
      const response = await envoyerRequeteApi(query, '');
      console.log('Datas des demandes', response.datas);
      setDemandes(response.datas);
    } catch (error) {
      console.error('Error fetching demandes', error);
    }
  }, [dateDebut, dateFin, etatDemande]);

  useEffect(() => {
    fetchDemandes();
  }, [fetchDemandes, dateDebut, dateFin, etatDemande]);

  const handleDateChange = useCallback((setter) => (e) => {
    setter(e.target.value);
  }, []);

  const handleEtatChange = useCallback((e) => {
    setEtatDemande(e.target.value);
  }, []);

  const filteredDemandes = useMemo(() => {
    let filtered = isAdminOrPeche
      ? demandes
      : demandes.filter(demande => {
          switch (user.code_groupe) {
            case 'COMMISSION':
              return demande.id_etat === 5 || demande.id_etat === 2;
            case 'SADMIN':
              return demande.id_etat === 5 || demande.id_etat === 3;
            case 'DOUANE':
              if (user.id_groupe === 3 || user.id_groupe === 9 ) {
                return demande.id_etat === 5 || demande.id_etat === 6 || demande.id_etat === -1;
              }
              else {
              return demande.id_etat === 5 || demande.id_etat === 4 || demande.id_etat === 6 || demande.id_etat === -1;
              }
            default:
              return true;
          }
        });
    
    setFilteredDemandesCount(filtered.length);
    return filtered;
  }, [isAdminOrPeche, demandes,user.id_groupe, user.code_groupe]);
  useEffect(() => {
    fetchDemandes();
  }, [fetchDemandes]);

  const fetchEtats = useCallback(async () => {
    let query = `
    SELECT id_etat, libelle_etat, taf
    FROM etat_demande
    ORDER BY id_etat ASC`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setEtats(response.datas || []);
    } catch (error) {
      console.error('Error fetching etats', error);
    }
  }, []);

  useEffect(() => {
    fetchEtats();
  }, [fetchEtats]);

  const fetchTicketsDispo = useCallback(async () => {
    try {
      const query = `select sum(qte) as tickets_dispo from ticket_carb tc`;
      const response = await envoyerRequeteApi(query, '');
      setTicketsDispo(response.datas[0].tickets_dispo);
    } catch (error) {
      console.error('Error fetching tickets dispo', error);
    }
  }, []);

  useEffect(() => {
    fetchTicketsDispo();
    const interval = setInterval(fetchTicketsDispo, 30000);
    return () => clearInterval(interval);
  }, [fetchTicketsDispo]);

  const handleFilter = useCallback(async () => {
    let query = `
    SELECT *
    FROM list_demandes
    WHERE date_demande BETWEEN '${dateDebut}' AND '${dateFin}'
    ${etatDemande ? `AND id_etat = ${etatDemande}` : ''}
    ORDER BY id_demande DESC`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setDemandes(response.datas);
    } catch (error) {
      console.error('Error filtering demandes', error);
    }
  }, [dateDebut, dateFin, etatDemande]);

  const handleNewDemande = () => {
    setShowAddCommande(true);
  };

  const handleDelete = useCallback(async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette demande ? Cette action est irréversible.')) {
        let query = `SELECT del_demande(${id}, ${user.id_agent}) AS result`;

        console.log('Demande à supprimer', query);
        try {
            const response = await envoyerRequeteApi(query, '');
            
            const result = response.datas[0].result;

            if (result.code === 200) {
                alert('Suppression réussie.');
                fetchDemandes(); // Rafraîchir la liste des demandes après la suppression réussie
            } else {
                alert(`Erreur: ${result.donnee}. Détails: ${result.detail || 'Aucun détail disponible.'}`);
            }
        } catch (error) {
            console.error('Erreur lors de la suppression de la demande', error);
            alert('Une erreur s\'est produite lors de la tentative de suppression. Veuillez réessayer.');
        }
    }
}, [fetchDemandes, user.id_agent]);

  const handleView = useCallback((demande) => {
    setSelectedDemande(demande);
    setShowPopup(true);
  }, []);

  const calculateTickets = useCallback((qteCmde) => {
    return Math.ceil(qteCmde / 10);
  }, []);



  const getIndicativeText = useCallback(() => {
    if (isAdminOrPeche) {
      return "Vous pouvez voir et filtrer toutes les demandes.";
    }
    switch (user.code_groupe) {
      case 'PETROLIER':
        return "Vous ne pouvez agir que sur les demandes ayant le statut PAIEMENT ATTENDU";
      case 'SADMIN':
        return "Vous ne pouvez agir que sur les demandes ayant le statut LIVRAISON ATTENDUE";
      case 'DOUANE':
        if (user.id_groupe === 3 || user.id_groupe === 9) {
          return "Vous ne pouvez agir que sur les demandes ayant le statut ACCORD CHEF DE BRIGADE/REJETEE";
        }
        else {
        return "Vous ne pouvez agir que sur les demandes ayant le statut TICKETS GENERES";
        }
      default:
        return "";
    }
  }, [isAdminOrPeche,user]);
  return (
    <>
      <Header user={user} />
      <div className="container-list-demandes">
        <h2>Liste des demandes</h2>
        <div className="tickets-dispo-circle">
          <span className="tickets-dispo-number">{ticketsDispo}</span>
          <span className="tickets-dispo-label">Tickets disponibles</span>
        </div>
        <div className="alert-indicative">{getIndicativeText()}</div>
        <div className="form-container">
          <div className="form-group">
            <label htmlFor="dateDebut">Date de début</label>
            <input
              type="date"
              id="dateDebut"
              value={dateDebut}
              onChange={handleDateChange(setDateDebut)}
            />
            <label htmlFor="dateFin">Date de fin</label>
            <input
              type="date"
              id="dateFin"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
            />
            <div className="form-group">
              <label htmlFor="etatDemande">État de la demande</label>
              <select
                id="etatDemande"
                value={etatDemande}
                onChange={handleEtatChange}
              >
                <option value="">Tous les états</option>
                {etats.map((etat) => (
                  <option key={etat.id_etat} value={etat.id_etat}>
                    {etat.libelle_etat}
                  </option>
                ))}
              </select>
            </div>
            <div className="filtered-count-label">
              Nombre de demandes affichées : {filteredDemandesCount}
            </div>
            <div className="button-group">
              <button className="rounded-button btn-primary" onClick={handleFilter}>
                Filtrer
              </button>
            </div>
            <button 
              className="bouton-nouveau" 
              onClick={handleNewDemande} 
              disabled={!isUserAuthorized}
              hidden={!isUserAuthorized}
            >
              Nouvelle demande
            </button>
          </div>
        </div>
        <div className="demandes-container">
          {filteredDemandes.map((demande) => (
            <div key={demande.id_demande} className="card-demande">
              <img className="ticket-img" src={`${process.env.PUBLIC_URL}/images/ticket-img.png`} alt="Ticketpetrol" />
              <h3>Réf: {demande.ref_demande}</h3>
              <p className="text-muted"> <FontAwesomeIcon icon={faCheckCircle} /> {demande.date_demande}</p>
              <p><FontAwesomeIcon icon={faClipboardList} /> <strong>État:</strong> {demande.libelle}</p>
              <p><FontAwesomeIcon icon={faQrcode} /> <strong>Quantité:</strong> {demande.qte_cmde} litres soit {calculateTickets(demande.qte_cmde)} Tickets</p>
              <div className="button-group">
                <button className="rounded-button btn-info" onClick={() => handleView(demande)}>Afficher</button>
                <button className="rounded-button btn-danger" hidden={user.code_groupe === 'DOUANE' || user.code_groupe !== 'ADMIN'} onClick={() => handleDelete(demande.id_demande)}>Supprimer</button>
              </div>
            </div>
          ))}
        </div>
        <PopupDemande
          demande={selectedDemande}
          show={showPopup}
          onHide={() => setShowPopup(false)}
          refreshDemandes={fetchDemandes}
          etats={etats}
          user={user}
        />
        <AddCommande 
          show={showAddCommande}
          onHide={() => setShowAddCommande(false)}
          refreshDemandes={fetchDemandes}
          user={user}
        />
      </div>
    </>
  );
};

export default ListDemande;
