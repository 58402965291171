import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import EditPecheurModal from './EditPecheurModal'; // Import du nouveau composant
import '../styles/PopupPecheur.css'; // Assurez-vous de créer et ajouter le style pour ce composant

const PopupPecheur = ({ show, onHide, pirogue }) => {
  const [pecheurs, setPecheurs] = useState([]);
  const [selectedPecheur, setSelectedPecheur] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const fetchPecheurs = useCallback(async () => {
    if (!pirogue) return;

    let query = `
      SELECT id_pecheur, nom_pecheur, num_id, tel_pecheur, actif
      FROM liste_pecheur
      WHERE matricule = '${pirogue.matricule}'
      ORDER BY nom_pecheur`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setPecheurs(response.datas);
    } catch (error) {
      console.error('Error fetching pecheurs', error);
    }
  }, [pirogue]);

  useEffect(() => {
    fetchPecheurs();
  }, [fetchPecheurs]);

  const handleEditPecheur = (pecheur) => {
    setSelectedPecheur(pecheur);
    setShowEditModal(true);
  };

  const handleSavePecheur = () => {
    setShowEditModal(false);
    fetchPecheurs(); // Recharger les pêcheurs après l'édition
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered className="rounded-popup">
        <Modal.Header closeButton>
          <Modal.Title>Pêcheurs pour {pirogue ? pirogue.nom_pirogue : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-warning rounded-popup">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Numéro ID</th>
                <th>Téléphone</th>
                <th>Actif</th>
                <th>Actions</th> {/* Nouvelle colonne Actions */}
              </tr>
            </thead>
            <tbody>
              {pecheurs.map((pecheur, index) => (
                <tr key={index}>
                  <td>{pecheur.nom_pecheur}</td>
                  <td>{pecheur.num_id}</td>
                  <td>{pecheur.tel_pecheur}</td>
                  <td>{pecheur.actif ? 'Oui' : 'Non'}</td>
                  <td>
                    <Button 
                      variant="warning" 
                      onClick={() => handleEditPecheur(pecheur)}
                    >
                      Éditer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal pour éditer le pêcheur */}
      {selectedPecheur && (
        <EditPecheurModal
          show={showEditModal}
          onHide={() => setShowEditModal(false)}
          pecheur={selectedPecheur}
          onSave={handleSavePecheur}
        />
      )}
    </>
  );
};

export default PopupPecheur;
