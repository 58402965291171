import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { envoyerRequeteApi } from '../apis/api';
import '../styles/PopupSubvention.css'; // Assurez-vous de créer et ajouter le style pour ce composant

const PopupSubvention = ({ show, onHide, pirogue }) => {
  const [subventions, setSubventions] = useState([]);

  const fetchSubventions = useCallback(async () => {
    if (!pirogue) return;
    
    let query = `
      SELECT date_subvention, qte
      FROM ticket_remis
      WHERE matricule = '${pirogue.matricule}' ORDER BY date_subvention DESC`;

    try {
      const response = await envoyerRequeteApi(query, '');
      setSubventions(response.datas);
    } catch (error) {
      console.error('Error fetching subventions', error);
    }
  }, [pirogue]);

  useEffect(() => {
    fetchSubventions();
  }, [fetchSubventions]);

  return (
    <Modal show={show} onHide={onHide} centered className="rounded-popup">
      <Modal.Header closeButton>
        <Modal.Title>Subventions pour {pirogue ? pirogue.nom_pirogue : ''}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-warning rounded-popup">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Nombre de tickets</th>
            </tr>
          </thead>
          <tbody>
            {subventions.map((subvention, index) => (
              <tr key={index}>
                <td>{new Date(subvention.date_subvention).toLocaleDateString('fr-FR', { day: '2-digit', month: 'short', year: 'numeric' })}</td>
                <td>{subvention.qte} soit {subvention.qte * 10}litres</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupSubvention;
