import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { QrReader } from 'react-qr-reader';
import { envoyerRequeteApi } from '../apis/api';

const GetPirogue = ({ show, onHide, onScan }) => {
  // États pour gérer le chargement, les erreurs, le scan et l'état de la caméra
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [scanned, setScanned] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);

  useEffect(() => {
    // Création d'un AbortController pour gérer l'annulation des requêtes
    let abortController = new AbortController();

    if (show) {
      // Activer la caméra si le modal est visible
      setCameraActive(true);
    } else {
      // Réinitialiser l'état lorsque le modal est caché
      setCameraActive(false);
      setScanned(false);
      setLoading(false);
      setError('');
    }

    // Nettoyage de l'AbortController lors du démontage ou du changement de show
    return () => {
      abortController.abort();
    };
  }, [show]);

  const handleScan = async (result, error) => {
    // Ne rien faire si la caméra n'est pas active ou si le code a déjà été scanné
    if (!cameraActive || scanned) return;
    
    if (result) {
      const matricule = result.text;

      // Vérifier si le format du matricule est correct
      if (matricule.length < 9) {
        setError('Format matricule incorrect');
        return;
      }

      // Marquer le code comme scanné et commencer le chargement
      setScanned(true);
      setLoading(true);
      let abortController = new AbortController();

      try {
        // Préparer et envoyer la requête API
        const query = `SELECT * FROM liste_pirogue WHERE matricule = '${matricule}'`;
        const response = await envoyerRequeteApi(query, { signal: abortController.signal });

        if (response.datas.length > 0) {
          // Si une pirogue est trouvée, transmettre les détails au composant parent
          onScan(response.datas[0]);
          setCameraActive(false);
        } else {
          // Sinon, afficher un message d'erreur
          setError('Aucune pirogue trouvée avec ce matricule.');
        }
      } catch (error) {
        // Gérer les erreurs de requête, sauf les annulations
        if (error.name !== 'AbortError') {
          setError('Erreur lors de la recherche de la pirogue.');
        }
      } finally {
        // Arrêter le chargement
        setCameraActive(false);
        setScanned(false);
        setLoading(false);
      }
    }

    // Gérer les erreurs de scan du QR code
    if (error) {
      console.error(error);
      setError('Erreur lors du scan du QR code.');
    }
  };

  const handleClose = () => {
    // Réinitialiser l'état et fermer le modal
    setCameraActive(false);
    setScanned(false);
    setLoading(false);
    setError('');
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Scanner le QR code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          // Afficher un spinner pendant le chargement
          <Spinner animation="border" />
        ) : (
          cameraActive && (
            // Afficher le lecteur QR si la caméra est active
            <QrReader
              onResult={(result, error) => {
                handleScan(result, error);
              }}
              style={{ width: '100%' }}
            />
          )
        )}
        {error && <div className="error-message">{error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GetPirogue;
